import { HTTPClient } from '@/api/HTTPClient'

export class AuthService {
  static Check() {
    return HTTPClient.get('/auth/check')
  }
  static logout() {
    return HTTPClient.post('/auth/logout')
  }
}
