import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import { EnterpriseQuotaCreateRequestDto } from '@/api/Services/License/dto/request/enterprise-quota-create-request.dto'
import { IrQuotaCreateRequestDto } from '@/api/Services/License/dto/request/ir-quota-create-request.dto'
import { LicenseTypeEnum } from '@/api/Services/License/enums/license-type.enum'
import { BaseQuotaSummaryResponseDto } from '@/api/Services/License/dto/response/base-quota-summary-response.dto'

export class QuotaService {
  static createEnterpirseQuota(payload: EnterpriseQuotaCreateRequestDto): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.post('/oem/quotas/enterprise', payload)
  }
  static createIRLicenseQuota(payload: IrQuotaCreateRequestDto): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.post('/oem/quotas/ir', payload)
  }
  static getAllByKey(payload: {
    licenseKey: string
    licenseType: LicenseTypeEnum
  }): Promise<AxiosResponse<ResponseDto<BaseQuotaSummaryResponseDto[]>>> {
    return HTTPClient.get('/oem/quotas', payload)
  }
}
