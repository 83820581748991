<template>
  <div class="sign-in">
    <div class="sign-in-form shadow-sm">
      <h3>Welcome Back</h3>
      <a
        href="http://localhost:3000/api/auth/sso/azure/url"
        class="azure-sso-ad-button"
        v-text="$t('Sign in with Azure AD - Local')"
        v-if="isLocal()"
      />
      <a href="/api/auth/sso/azure/url" class="azure-sso-ad-button" v-text="$t('Sign in with Azure AD')" v-else />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignIn',

  methods: {
    isLocal() {
      return process.env.VUE_APP_IS_LOCAL && process.env.VUE_APP_IS_LOCAL === 'true'
    },
  },
}
</script>

<style lang="scss">
$button-bg: #fe013e;

.sign-in {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;

  .azure-sso-ad-button {
    width: 100%;
    display: block;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    background-color: $button-bg;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: lighten($button-bg, 5);
    }

    &:active {
      background-color: darken($button-bg, 5);
    }
  }

  .sign-in-form {
    width: 420px;
    margin-top: 50px;
    padding: $spacer-5;
    border-radius: $radius;
    background-color: #fff;

    h3 {
      font-size: 22px;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
