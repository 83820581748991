<template>
  <el-dialog
    :visible.sync="showPanel"
    center
    width="500px"
    top="1vh"
    padding
    align-center="true"
    :before-close="closeNewLicensePanel"
    custom-class="dialog-class"
  >
    <span slot="title" class="el-dialog__title">{{
      'Create AIR License Key for ' + getEnvironment(environment) + ' Customers'
    }}</span>

    <el-form :model="newLicense" status-icon ref="ruleForm" label-width="150px" class="new-license-form" size="small">
      <el-divider class="new-license-divider new-license-info-label">License Information</el-divider>
      <el-form-item label="Tags" prop="tags">
        <el-select
          v-model="newLicense.tags"
          multiple
          filterable
          remote
          :allow-create="hasPermission('tags-create')"
          placeholder="Please enter a tag"
          name="tags"
          :remote-method="getTags"
          :loading="tagsLoading"
          class="new-license-input-element"
        >
          <el-option v-for="item in tagsOptions" :key="item.id" :label="item.val" :value="item.val"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Model" prop="model">
        <el-select v-model="newLicense.model" placeholder="Select" class="new-license-input-element">
          <el-option
            v-for="item in licenseTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Is Offline" prop="isOffline" v-if="newLicense.model === 1">
        <el-checkbox v-model="newLicense.isOffline"></el-checkbox>
      </el-form-item>
      <el-form-item v-if="newLicense.model === 1" label="Server Capacity" prop="serverCapacity">
        <el-input-number
          v-model="newLicense.serverCapacity"
          controls-position="right"
          :min="1"
          :max="1000"
          class="new-license-input-element"
        />
      </el-form-item>
      <el-form-item v-if="newLicense.model === 1" label="Asset Capacity" prop="endpointCapacity">
        <el-input-number
          v-model="newLicense.endpointCount"
          controls-position="right"
          :min="1"
          :max="1000000000"
          class="new-license-input-element"
        />
      </el-form-item>
      <el-divider class="new-license-divider">License Duration Information</el-divider>
      <el-form-item label="License Period" prop="period">
        <el-select v-model="period" placeholder="Select" class="new-license-input-element">
          <el-option v-for="(item, index) in periodOptions" :key="index" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Start Date" prop="startDate">
        <el-date-picker
          v-model="newLicense.startDate"
          type="date"
          placeholder="Pick a day"
          :clearable="false"
          class="new-license-date-input"
          @change="handleStartDateChangedEvent()"
        />
      </el-form-item>
      <el-form-item label="Expiration Date" prop="endDate">
        <el-date-picker
          v-model="newLicense.endDate"
          type="date"
          :disabled="isEndDateDisabled()"
          placeholder="Pick a day"
          :clearable="false"
          @change="handleEndDateChangedEvent()"
          class="new-license-date-input"
          :picker-options="endDateOptions"
        />
      </el-form-item>
      <el-form-item label="Expiration Days" prop="endDate">
        <el-input-number
          v-model="endDays"
          controls-position="right"
          @change="handleExpirationDaysChangeEvent()"
          :min="1"
          class="new-license-input-element"
          :disabled="isEndDateDisabled()"
        />
      </el-form-item>
    </el-form>
    <el-divider class="new-license-divider">Customer Information</el-divider>

    <create-license-company-info
      :reset="resetCustomerInfo"
      @selectionCancelled="customerSelectionCleared"
      @selectionCompleted="customerSelected"
    />

    <el-form>
      <el-form-item>
        <el-button type="primary" @click="createNewLicense()" :disabled="!customerReady">Save</el-button>
        <el-button @click="closeNewLicensePanel()">Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { LicenseEnvironmentEnum } from '@/api/Services/License/enums/license-environment.enum'
import { TagsService } from '@/api/Services/License/TagsService'
import { PeriodEnum, PeriodEnumUtils } from '@/api/Services/License/enums/period-enum'
import { UtilityService } from '@/api/Services/License/UtilityService'
import { LicenseTypeEnumUtils } from '@/api/Services/License/enums/license-type.enum'
import { LicensesService } from '@/api/Services/License/LicensesService'
import { QuotaService } from '@/api/Services/License/QuotaService'
import { sleep } from '@/utils/Helpers'
import CreateLicenseCompanyInfo from '@/views/License/children/subComp/licenseCreateSubComponents/licenseCreationCompanyInfo.vue'
import { LicenseCreateRequestUsingHubspotDto } from '@/api/Services/License/dto/request/license-create-request-using-hubspot.dto'

export default {
  name: 'CreateLicensePanel',

  components: {
    CreateLicenseCompanyInfo,
  },

  data: function () {
    return {
      showPanel: false,
      resetCustomerInfo: false,
      newLicense: {
        tags: [],
        model: LicenseTypeEnumUtils.getDefaultLicenseType(),
        isOffline: false,
        serverCapacity: 1,
        endpointCount: 100,
        startDate: dayjs(),
        endDate: dayjs().add(1, 'year'),
      },
      endDays: dayjs().add(1, 'year').diff(dayjs(), 'day'),
      tagsLoading: false,
      tagsOptions: [],
      licenseTypeOptions: LicenseTypeEnumUtils.getLicenseTypesForDropdown(),
      endDateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        },
      },
      periodOptions: PeriodEnumUtils.getPeriodOptionsForDropdown(),
      period: PeriodEnumUtils.getDefaultPeriod(),
      localeOptions: [],
      customerReady: false,
      customerDetails: {},
    }
  },

  props: {
    panelDisplayStatus: Boolean,
    environment: Number,
  },

  methods: {
    // MODAL METHODS
    updateShowPanelStatus() {
      this.showPanel = this.panelDisplayStatus
    },

    closeNewLicensePanel() {
      this.showPanel = false
      this.resetNewLicense()
      this.$emit('displayStatus', this.panelDisplayStatus)
    },

    getEnvironment(env) {
      return LicenseEnvironmentEnum[env]
    },

    isEndDateDisabled() {
      return this.period !== PeriodEnum.Custom
    },

    handleStartDateChangedEvent() {
      if (!dayjs.isDayjs(this.newLicense.startDate)) this.newLicense.startDate = dayjs(this.newLicense.startDate)
      if (this.period !== PeriodEnum.Custom) {
        this.newLicense.endDate = UtilityService.getNewDateFromPeriod(this.newLicense.startDate, this.period)
      } else {
        this.newLicense.endDate = this.newLicense.startDate.add(this.endDays, 'day')
      }
      this.updateEndDateSelectionLimit()
    },

    handleEndDateChangedEvent() {
      if (!dayjs.isDayjs(this.newLicense.endDate)) this.newLicense.endDate = dayjs(this.newLicense.endDate)
      if (this.period === PeriodEnum.Custom) {
        this.endDays = this.newLicense.endDate.diff(this.newLicense.startDate, 'day')
      }
    },

    handleExpirationDaysChangeEvent() {
      if (!dayjs.isDayjs(this.newLicense.startDate)) this.newLicense.startDate = dayjs(this.newLicense.startDate)
      this.newLicense.endDate = this.newLicense.startDate.add(this.endDays, 'day')
    },

    updateEndDateSelectionLimit() {
      const targetDate = this.newLicense.startDate.add(1, 'day').toDate()
      this.endDateOptions = {
        disabledDate(time) {
          return time.getTime() < targetDate
        },
      }
    },

    getTags(query) {
      if (query !== '') {
        this.tagsLoading = true

        this.$request(async () => {
          const { data } = await TagsService.getTags(query)
          this.tagsLoading = false
          if (data.success) {
            this.tagsOptions = data.result
              .filter((item) => {
                return item.toLowerCase().indexOf(query.toLowerCase()) > -1
              })
              .map((c) => {
                const id = this.getHash(c)
                return { id: id, val: c }
              })
          }
        })
      } else {
        this.options = []
      }
    },

    getHash(input) {
      let hash = 0,
        len = input.length
      for (let i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i)
        hash |= 0 // to 32bit integer
      }
      return hash
    },

    customerSelectionCleared() {
      this.customerReady = false
    },

    customerSelected(customer) {
      this.customerReady = true
      this.customerDetails = customer
    },

    resetNewLicense() {
      this.newLicense = {
        tags: [],
        model: LicenseTypeEnumUtils.getDefaultLicenseType(),
        isOffline: false,
        serverCapacity: 1,
        endpointCount: 1,
        startDate: dayjs(),
        endDate: dayjs().add(1, 'year'),
      }
      this.period = PeriodEnumUtils.getDefaultPeriod()
      this.customerDetails = {}
      this.resetCustomerInfo = !this.resetCustomerInfo
    },

    createNewLicense() {
      if (!this.customerReady) {
        return
      }
      const licenseInformation = new LicenseCreateRequestUsingHubspotDto()
      licenseInformation.type = this.newLicense.model
      licenseInformation.isOffline = this.newLicense.isOffline
      licenseInformation.tags = []
      licenseInformation.environment = parseInt(this.environment)
      licenseInformation.hubspotCompanyId = this.customerDetails.company.id
      licenseInformation.hubspotContactId = this.customerDetails.contact.id
      licenseInformation.hubspotCompanyName = this.customerDetails.company.companyName
      licenseInformation.hubspotContactEmail = this.customerDetails.contact.email
      licenseInformation.hubspotContactFirstname = this.customerDetails.contact.firstName
      licenseInformation.hubspotContactLastname = this.customerDetails.contact.lastName
      this.newLicense.tags.forEach((p) => {
        licenseInformation.tags.push(p)
      })
      this.$request(async () => {
        const { data } = await LicensesService.createLicenseWithHubspotCustomer(licenseInformation)
        if (data.success) {
          const quota = {}
          quota.licenseKey = data.result
          quota.activationDate = this.newLicense.startDate.toDate()
          quota.expirationDate = this.newLicense.endDate.toDate()
          this.$request(async () => {
            if (this.newLicense.model === 1) {
              quota.serverCapacity = this.newLicense.serverCapacity
              quota.totalClientCapacity = this.newLicense.endpointCount
              await QuotaService.createEnterpirseQuota(quota)
            } else if (this.newLicense.model === 2) {
              quota.caseCapacity = 30
              quota.caseLifetime = 30
              await QuotaService.createIRLicenseQuota(quota)
            }
            await sleep(500)
            this.resetCustomerInfo = !this.resetCustomerInfo
            this.resetNewLicense()
            this.closeNewLicensePanel()
            this.$emit('reload')
          })
        }
      }, this.$waiters.License.LicensesCreate)
    },
  },

  watch: {
    panelDisplayStatus: function () {
      this.updateShowPanelStatus()
    },

    period: function () {
      if (this.period !== PeriodEnum.Custom) {
        if (!dayjs.isDayjs(this.newLicense.startDate)) this.newLicense.startDate = dayjs(this.newLicense.startDate)
        this.newLicense.endDate = UtilityService.getNewDateFromPeriod(this.newLicense.startDate, this.period)
        this.endDays = this.newLicense.endDate.diff(this.newLicense.startDate, 'day')
      }
    },
  },
}
</script>

<style lang="scss">
.new-license-input-element {
  width: 95%;
}
.new-license-input-element.el-input {
  width: 95%;
}
.new-license-date-input.el-date-editor.el-input {
  width: 95%;
}

.dialog-class {
  .el-dialog__header {
    padding: 20px 20px 0px;
  }
  .el-dialog__body {
    padding: 2px 25px 10px 25px !important;
  }
}
</style>
