import { Type } from 'class-transformer'
import { IsDate, IsEmail, IsNotEmpty, IsNumber, IsString } from 'class-validator'

export class CreateCustomerMarketplaceDto {
  @IsNotEmpty()
  @IsString()
  firstname: string

  @IsNotEmpty()
  @IsString()
  lastname: string

  @IsNotEmpty()
  @IsString()
  domain: string

  @IsNotEmpty()
  @IsString()
  company: string

  @IsNotEmpty()
  @IsEmail()
  email: string

  @IsNotEmpty()
  @IsString()
  password: string

  @IsNotEmpty()
  @IsString()
  marketplaceId: string

  @IsNotEmpty()
  @IsString()
  entitlementDimension: string

  @IsNotEmpty()
  @IsNumber()
  entitlementValue: number

  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  entitlementExpiration: Date
}
