<template>
  <label class="v-form-check" :class="clazz" :for="`v-check-${_uid}`" :name="null">
    <input
      :value="val"
      class="sr-only"
      type="checkbox"
      v-bind="$attrs"
      :checked="value"
      v-on="inputListeners"
      :id="`v-check-${_uid}`"
    />

    <!-- Icon -->
    <span class="v-form-check-input" :class="{ 'mr-2': text || $slots.default }">
      <VIcon name="check" width="10px" height="8px" />
    </span>

    <!-- Label -->
    <slot />

    <template v-if="text">
      {{ text }}
    </template>
  </label>
</template>

<script>
export default {
  name: 'VCheck',

  props: {
    text: String,
    val: String,
    value: Boolean,
  },

  computed: {
    clazz() {
      return {
        '--checked': this.value,
        '--disabled': this.$attrs.disabled,
      }
    },

    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: (event) => this.$emit('input', event.target.checked),
      })
    },
  },
}
</script>

<style lang="scss">
.v-form-check {
  display: flex;
  user-select: none;
  align-items: center;
  margin: 0 !important;
  font-weight: $font-weight-medium !important;
  font-size: $font-size-normal !important;
  cursor: pointer;

  &-input {
    position: relative;
    width: 1em;
    height: 1em;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border: 1px solid $color-dark-20;
    border-radius: $radius;
    color: white;
    background-color: white;
  }

  &.--disabled {
    cursor: no-drop;
  }

  &.--checked &-input {
    background-color: $color-primary;
  }
}
</style>
