<template>
  <el-container class="tags-page">
    <el-header class="tags-header">
      <div class="tags-count">
        <h3 v-text="$t('Tokens')" />
      </div>
      <div class="versions-actions">
        <el-button
          type="primary"
          v-on:click="toggleNewApiTokenDialog('create')"
          primary
          icon="el-icon-circle-plus-outline"
        >
          Create Api Token
        </el-button>
      </div>
    </el-header>

    <VCard>
      <el-table
        :data="tableData"
        stripe
        empty-text="There are no tags"
        class="w-100"
        v-loading="$wait.is($waiters.License.Users)"
        height="77vh"
      >
        <!-- Val-->
        <el-table-column label="Token" min-width="100">
          <template v-slot="scope">
            {{ scope.row.token }}
          </template>
        </el-table-column>
        <el-table-column label="Expiration Date" min-width="100">
          <template v-slot="scope">
            {{ getFormattedDate(scope.row.expirationDate) }}
          </template>
        </el-table-column>
        <el-table-column label="Owner" min-width="100">
          <template v-slot="scope">
            {{ scope.row.ownerEmail }}
          </template>
        </el-table-column>
        <el-table-column label="Actions" min-width="100">
          <template v-slot="scope">
            <div class="operations">
              <!--<el-button type="text" @click="openEditUserDetail(scope.row)" size="small">Edit</el-button>-->
              <el-popconfirm title="Are you sure" @confirm="onClickDelete(scope.row.token)">
                <template #reference>
                  <el-button
                    type="text"
                    size="small"
                    :loading="$wait.is($waiters.License.Tokens)"
                    v-text="$t('Delete')"
                    :disabled="!hasPermission('tags-manage')"
                  >
                  </el-button>
                </template>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handlePaginationPageChange"
        @size-change="handlePaginationSizeChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="sizes, prev, pager, next, jumper"
        :page-sizes="[25, 50, 100]"
        :total="totalEntityCount"
      >
      </el-pagination>
    </VCard>
    <new-token-component
      :panel-display-status="newTokenPanelVisible"
      :update-data="selectedToken"
      @reload="fetchTokens(pageSize, currentPage)"
      @close="newTokenPanelVisible = false"
    >
    </new-token-component>
  </el-container>
</template>

<script>
import { DateFormats, UtilityService } from '@/api/Services/License/UtilityService'
import { PaginatedApiTokensRequestDto } from '@/api/Services/License/dto/request/paginated-api-tokens-request.dto'
import { ApiTokensService } from '@/api/Services/License/ApiTokensService'
import NewTokenComponent from '@/views/License/children/subComp/newTokenPanel.vue'

export default {
  components: {
    NewTokenComponent,
  },

  data() {
    return {
      tableData: [],
      totalEntityCount: 0,
      pageSize: 25,
      currentPage: 0,
      newTokenPanelVisible: false,
      selectedToken: null,
    }
  },

  methods: {
    fetchTokens(pageSize, selectedPage) {
      this.$request(async () => {
        const request = new PaginatedApiTokensRequestDto()
        request.pageNumber = selectedPage
        request.pageSize = pageSize
        const { data } = await ApiTokensService.getPaginated(request)
        if (data.success) {
          this.tableData = data.result.entities
          this.totalEntityCount = data.result.totalEntityCount
        }
      }, this.$waiters.License.Tokens)
    },

    getFormattedDate(date) {
      let formattedDate = UtilityService.getFormattedDate(date, DateFormats.SECONDS)
      if (formattedDate === 'Invalid Date') formattedDate = '—'
      return formattedDate
    },

    handlePaginationPageChange(page) {
      this.fetchTokens(this.pageSize, page)
    },

    handlePaginationSizeChange(size) {
      this.fetchTokens(size, this.currentPage)
    },

    onClickDelete(token) {
      this.$request(async () => {
        const { data } = await ApiTokensService.delete(token)
        if (data.success) {
          this.fetchTokens(this.pageSize, this.currentPage)
        }
      }, this.$waiters.License.Tokens)
    },

    toggleNewApiTokenDialog(type) {
      if (type === 'create') {
        this.selectedToken = null
      }
      this.newTokenPanelVisible = !this.newTokenPanelVisible
    },
  },

  mounted() {
    this.fetchTokens(this.pageSize, 1)
  },
}
</script>
