import { HTTPClient } from '@/api/HTTPClient'
import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { VersionRequestDto } from '@/api/Services/License/dto/request/version-request.dto'

export class VersionsService {
  static getAll(): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.get('/oem/versions')
  }

  static Create(payload: VersionRequestDto): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.post('/oem/versions', payload)
  }

  static Update(id: number, payload: VersionRequestDto): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.put(`/oem/versions/${id}`, payload)
  }
}
