import { IsDate, IsOptional, IsString } from 'class-validator'
import { Type } from 'class-transformer'
export class CreateApiTokenDto {
  @IsString()
  token: string

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  expirationDate: Date
}
