import { IsIn, IsNumber, IsOptional, IsString, Min } from 'class-validator'

export class PaginatedApiTokensRequestDto {
  @IsOptional()
  @IsNumber()
  @Min(0)
  pageNumber = 1

  @IsOptional()
  @IsNumber()
  @Min(0)
  pageSize = 10

  @IsOptional()
  @IsString()
  @IsIn(['ASC', 'DESC'])
  sortType = 'ASC'

  @IsOptional()
  @IsString()
  @IsIn(['createdAt', 'updatedAt', 'expirationDate'])
  sortBy = 'createdAt'
}
