<template>
  <VCard class="mx-auto infoView">
    <div style="text-align: center">
      <h3>{{ $t('Creating an AIR Instance') }}</h3>
    </div>
    <hr class="mb-4 mt-2 bc-dark-5 w-100" />
    <div>
      <!-- Steps -->
      <div
        v-for="step in creatingSteps"
        :key="step.processingText"
        :id="step.processing ? null : step.id"
        class="d-flex flex-items-center mb-4"
      >
        <!-- Loader -->
        <VCheckLoader size="26px" border="4px" color="#25af54" :loading="step.processing" />
        <!-- Description -->
        <span
          v-text="step.processing ? step.processingText : step.completedText"
          class="fs-big fw-medium c-dark-90 ml-3"
        />
      </div>

      <!-- E-Mail -->
      <div v-if="isCreationComplete" class="text-center">
        <hr class="mb-4 mt-2 bc-dark-5 w-100" />
        <!-- Seconds -->
        <h3 v-text="$t(`Your domain is ${hostname}`)" class="mb-3" />
        <h4 v-text="$t(`You will be directed to your AIR in ${seconds} seconds.`)" class="mb-3" />
      </div>
    </div>
  </VCard>
</template>

<script>
import * as Helpers from '@/utils/Helpers'
import { MarketplaceService } from '@/api/Services/Marketplace/MarketplaceService'

export default {
  name: 'CreatingStepsForMarketplace',

  props: {
    licenseKey: String,
  },

  data() {
    // !! Important
    // We use id field for google tag manager integration.
    // If we change these id values we must notify marketing team (Emre Çetin)
    const creatingSteps = [
      {
        id: 'creating-server',
        processingText: this.$t('Creating a server...'),
        completedText: this.$t('Server has been created.'),
        processing: true,
      },
      {
        id: 'creating-domain',
        processingText: this.$t('Creating a domain name for your company...'),
        completedText: this.$t('Domain name has been created for your company.'),
        processing: true,
      },
      {
        id: 'air-installation',
        processingText: this.$t('Binalyze AIR is installing...'),
        completedText: this.$t('Binalyze AIR has been installed.'),
        processing: true,
      },
      {
        id: 'air-setup',
        processingText: this.$t('SSL Certificate and other security steps are in progress...'),
        completedText: this.$t('SSL Certificate and other security steps has been completed.'),
        processing: true,
      },
    ]

    let seconds = 10
    if (this.licenseKey === 'dry-run-dry-run') {
      seconds = 100000000
    }

    return {
      creatingSteps: creatingSteps,
      seconds: seconds,
    }
  },

  created() {
    this.checkStatus()
  },

  beforeDestroy() {
    clearInterval(this.countDownIntervalId)
  },

  computed: {
    isCreationComplete() {
      return this.creatingSteps.every((i) => !i.processing)
    },
  },

  methods: {
    countDownToLaunch() {
      this.countDownIntervalId = setInterval(() => {
        this.seconds--
        if (this.seconds === 0) {
          clearInterval(this.countDownIntervalId)
          this.launchAIRInstance()
        }
      }, 1e3)
    },

    launchAIRInstance() {
      window.location.href = `https://${this.hostname}`
    },

    async checkStatus() {
      let isCompleted = false

      const onSuccess = async () => {
        const { data } = await MarketplaceService.checkInstanceStatus(this.licenseKey)
        const status = data.result.status

        this.hostname = data.result.hostname

        if (status.airSetup.completed) {
          isCompleted = true
          this.$emit('on-complete')
          this.countDownToLaunch()
        }

        this.creatingSteps[0].processing = !status.server.completed
        this.creatingSteps[1].processing = !status.domain.completed
        this.creatingSteps[2].processing = !status.airBootstrap.completed
        this.creatingSteps[3].processing = !status.airSetup.completed
      }

      const onError = () => {
        isCompleted = true
        this.$router.push('/')
      }

      do {
        await this.$request(onSuccess, null, onError)
        await Helpers.sleep(1e3)
      } while (!isCompleted)
    },
  },
}
</script>

<style lang="scss">
.infoView {
  width: 460px;
}
</style>
