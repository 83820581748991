import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import { TagsPaginatedRequestDto } from '@/api/Services/License/dto/request/tags-paginated-request.dto'
import { TagDetailedResponseDto } from '@/api/Services/License/dto/response/tag-detailed-response.dto'
import { PagingQueryResultDto } from '@/api/Services/License/dto/paging-query-result.dto'
import { TagUpdateRequestDto } from '@/api/Services/License/dto/request/tag-update-request.dto'

export class TagsService {
  static getTags(payload: string): Promise<AxiosResponse<ResponseDto<string[]>>> {
    return HTTPClient.get('/oem/tags', { val: payload })
  }

  static updateTag(payload: TagUpdateRequestDto, id: number): Promise<AxiosResponse<ResponseDto<string[]>>> {
    return HTTPClient.patch(`/oem/tags/${id}`, payload)
  }

  static getPaginated(
    payload: TagsPaginatedRequestDto,
  ): Promise<AxiosResponse<ResponseDto<PagingQueryResultDto<TagDetailedResponseDto[]>>>> {
    return HTTPClient.post(`/oem/tags/paginated/`, payload)
  }

  static delete(id: number): Promise<any> {
    return HTTPClient.delete(`/oem/tags/${id}`)
  }
}
