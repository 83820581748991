import dayjs from 'dayjs'
import { PeriodEnum } from '@/api/Services/License/enums/period-enum'

export class UtilityService {
  static getFormattedDate(date: string, format: DateFormats): string {
    return dayjs(date).format(format)
  }
  static getNewDateFromPeriod(date: dayjs.Dayjs, period: PeriodEnum) {
    switch (period) {
      case PeriodEnum.Per2Week:
        return date.add(2, 'week')
      case PeriodEnum.PerMonth:
        return date.add(1, 'month')
      case PeriodEnum.Per2Month:
        return date.add(2, 'month')
      case PeriodEnum.Per3Month:
        return date.add(3, 'month')
      case PeriodEnum.Per6Month:
        return date.add(6, 'month')
      case PeriodEnum.PerYear:
        return date.add(1, 'year')
      case PeriodEnum.Per2Year:
        return date.add(2, 'year')
      case PeriodEnum.Per3Year:
        return date.add(3, 'year')
    }
  }
}
export enum DateFormats {
  MINUTES = 'DD MMM YYYY HH:mm',
  DATE = 'DD MMM YYYY',
  SECONDS = 'DD MMM YYYY HH:mm:ss',
}
