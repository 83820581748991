import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faFilePdf as farFilePdf,
  faFileExcel,
  faFlag as farFaFlag,
  faFileAlt as farFileAlt,
  faFileArchive as farFileArchive,
  faFileClipboard as faFileClipboard,
  faCopy as farCopyLight,
  faCheckCircle as farCheckCircle,
  faTimesCircle as farTimesCircle,
} from '@fortawesome/free-regular-svg-icons'

import {
  faHistory,
  faTable,
  faCogs,
  faHome,
  faUser,
  faBell,
  faAngleRight,
  faAngleLeft,
  faTrash,
  faPencilAlt,
  faExclamationTriangle,
  faTimes,
  faSearch,
  faBolt,
  faAngleDown,
  faAngleUp,
  faClipboardCheck,
  faClipboardList,
  faEye,
  faDesktop,
  faTimesCircle,
  faClock,
  faMemory,
  faFolder,
  faCheck,
  faCheckCircle,
  faFileAlt,
  faFile,
  faLock,
  faEyeSlash,
  faSpinner,
  faCircleNotch,
  faCalendar,
  faCalendarAlt,
  faCheckDouble,
  faFingerprint,
  faArrowRight,
  faSortAmountDownAlt,
  faSort,
  faTrashAlt,
  faDotCircle,
  faEllipsisH,
  faEllipsisV,
  faPlus,
  faCommentAlt,
  faComment,
  faInbox,
  faFilter,
  faSignOutAlt,
  faSync,
  faRedoAlt,
  faFont,
  faList,
  faDownload,
  faArrowLeft,
  faSitemap,
  faFolderOpen,
  faUsers,
  faKey,
  faArchive,
  faPowerOff,
  faCrosshairs,
  faUpload,
  faNetworkWired,
  faBuilding,
  faGlobe,
  faExternalLinkAlt,
  faExchangeAlt,
  faInfo,
  faInfoCircle,
  faBars,
  faChartPie,
  faGripLines,
  faQuestionCircle,
  faTags,
  faTag,
  faMinus,
  faChevronRight,
  faChevronDown,
  faFlag,
  faEdit,
  faThumbtack,
  faMapMarkerAlt,
  faMapPin,
  faExpandAlt,
  faCompressAlt,
  faExpand,
  faCompress,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faClipboard,
  faCopy,
  faWrench,
  faHourglass,
  faHourglassStart,
  faHourglassHalf,
  faHourglassEnd,
  faMicrochip,
  faHdd,
  faDatabase,
  faUnlock,
  faArrowUp,
  faArrowDown,
  faChevronLeft,
  faChevronUp,
  faGripVertical,
  faGripHorizontal,
  faAt,
  faBook,
  faBookOpen,
  faQuestion,
  faCloudUploadAlt,
  faClone,
  faFileImport,
  faEnvelope,
  faPlay,
  faAsterisk,
  faTasks,
  faFilePdf,
  faHashtag,
  faPlug,
  faRotate,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTasks,
  faHistory,
  faTable,
  faCogs,
  faHome,
  faUser,
  faBell,
  faAngleRight,
  faTrash,
  faPencilAlt,
  faExclamationTriangle,
  faTimes,
  faSearch,
  faBolt,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faClipboardCheck,
  faClipboardList,
  faEye,
  faDesktop,
  faTimesCircle,
  farTimesCircle,
  faPowerOff,
  faClock,
  faMemory,
  faFolder,
  faCheck,
  faCheckCircle,
  farCheckCircle,
  faFileAlt,
  faFile,
  faPlus,
  faComment,
  faCommentAlt,
  faFingerprint,
  faTrashAlt,
  faArrowRight,
  faLock,
  faEyeSlash,
  faSpinner,
  faCircleNotch,
  faCalendar,
  faCalendarAlt,
  faCheckDouble,
  faEllipsisH,
  faEllipsisV,
  faDotCircle,
  faSortAmountDownAlt,
  faSort,
  faInbox,
  faFilter,
  faFont,
  faList,
  faSignOutAlt,
  faSync,
  faCrosshairs,
  faRedoAlt,
  faArrowLeft,
  faUpload,
  faDownload,
  faSitemap,
  faFolderOpen,
  faUsers,
  faKey,
  faArchive,
  faNetworkWired,
  faBuilding,
  faGlobe,
  faExternalLinkAlt,
  faExchangeAlt,
  faInfo,
  faInfoCircle,
  faBars,
  faChartPie,
  faGripLines,
  faFilter,
  faQuestionCircle,
  faTags,
  faTag,
  faMinus,
  faChevronRight,
  faChevronDown,
  faFlag,
  farFaFlag,
  faEdit,
  faThumbtack,
  faMapMarkerAlt,
  faMapPin,
  faExpandAlt,
  faCompressAlt,
  faExpand,
  faCompress,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faClipboard,
  faFilePdf,
  faFileExcel,
  faFileClipboard,
  faCopy,
  faWrench,
  faHourglass,
  faHourglassStart,
  faHourglassHalf,
  faHourglassEnd,
  faMicrochip,
  faHdd,
  faDatabase,
  farFileAlt,
  faCalendarAlt,
  faUnlock,
  faArrowUp,
  faArrowDown,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faGripVertical,
  faGripHorizontal,
  faGlobe,
  faAt,
  faBookOpen,
  faBook,
  faQuestion,
  faCloudUploadAlt,
  faBuilding,
  faClone,
  faEnvelope,
  faFileImport,
  farFileArchive,
  faFileClipboard,
  faPlay,
  faAsterisk,
  farCopyLight,
  farFilePdf,
  faHashtag,
  faPlug,
  faRotate,
)

Vue.component('FaIcon', FontAwesomeIcon)
