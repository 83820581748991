<template>
  <el-dialog
    append-to-body
    width="20%"
    title="Change Tags"
    @opened="onDialogOpen"
    @close="resetAndClose()"
    :visible="show"
  >
    <el-form :model="form" ref="formRef" label-position="top">
      <el-form-item label="Tags" prop="tags">
        <el-select
          v-model="form.tags"
          multiple
          filterable
          remote
          :allow-create="hasPermission('tags-create')"
          placeholder="Please enter a tag"
          name="tags"
          :remote-method="getTags"
          :loading="tagsLoading"
          class="new-license-input-element"
        >
          <el-option v-for="item in tagsOptions" :key="item.id" :label="item.val" :value="item.val"> </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetAndClose()">Cancel</el-button>
      <el-button type="primary" @click="onClickSave" :loading="$wait.is($waiters.License.LicenseTagsUpdate)"
        >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { LicensesService } from '@/api/Services/License/LicensesService'
import { TagsService } from '@/api/Services/License/TagsService'

export default {
  name: 'changeTagsPanel',

  props: {
    license: {
      required: true,
    },
    show: Boolean,
  },

  data() {
    return {
      dialogVisible: false,
      form: {},
      tagsLoading: false,
      tagsOptions: [],
    }
  },

  methods: {
    resetAndClose() {
      this.form = {}
      this.$emit('close')
    },

    onClickSave() {
      const onSuccess = async () => {
        await LicensesService.updateTags(this.form, this.license.key)
        this.$emit('reload')
        this.resetAndClose()
      }

      this.$request(onSuccess, this.$waiters.License.LicenseTagsUpdate)
    },

    getTags(query) {
      if (query !== '') {
        this.tagsLoading = true

        this.$request(async () => {
          const { data } = await TagsService.getTags(query)
          this.tagsLoading = false
          if (data.success) {
            this.tagsOptions = data.result
              .filter((item) => {
                return item.toLowerCase().indexOf(query.toLowerCase()) > -1
              })
              .map((c) => {
                const id = this.getHash(c)
                return { id: id, val: c }
              })
          }
        })
      } else {
        this.options = []
      }
    },

    getIndividualTags(tagstr) {
      if (tagstr) {
        const tagsArry = tagstr.split(', ')
        if (tagsArry.length > 0) {
          let lastElement = tagsArry[tagsArry.length - 1]
          lastElement = lastElement.slice(0, -1)
          tagsArry[tagsArry.length - 1] = lastElement
        }
        return tagsArry
      }
      return []
    },

    onDialogOpen() {
      this.form = {
        tags: this.getIndividualTags(this.license.tags),
      }
    },

    getHash(input) {
      let hash = 0,
        len = input.length
      for (let i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i)
        hash |= 0 // to 32bit integer
      }
      return hash
    },
  },
}
</script>

<style scoped lang="scss">
.el-date-editor.el-input {
  width: 100%;
}
</style>
