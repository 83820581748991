<template>
  <div>
    <!-- LOADING DIV -->
    <div v-loading="$wait.is($waiters.OneMarketplace.SubscriptionPage)"></div>
    <div class="marketplace_container" v-if="waitForUs">
      <FaIcon :icon="['fas', 'check']" class="cross-icon-for-nosubs center" />
      <div class="marketplace_form_empty_row"></div>
      <h3 class="marketplace_multi_column center">
        Your request has reached us. Please expect an email from us with necessary information to react your AIR
        instance.
      </h3>
    </div>
    <div class="marketplace_container" v-if="expiredCustomer">
      <FaIcon :icon="['fas', 'times']" class="cross-icon-for-nosubs center" />
      <div class="marketplace_form_empty_row"></div>
      <h3 class="marketplace_multi_column center">You have no active subscriptions.</h3>
    </div>
    <el-form :model="request" :rules="rules" ref="loginForm" v-if="newCustomer" class="marketplace_container">
      <h3 class="marketplace_multi_column center">Confirm your subscription</h3>
      <div class="marketplace_form_empty_row"></div>

      <el-form-item prop="name" label="First Name">
        <el-input v-model="request.name" size="small"></el-input>
      </el-form-item>
      <el-form-item prop="surname" label="Last Name">
        <el-input v-model="request.surname" size="small"></el-input>
      </el-form-item>

      <el-form-item prop="email" label="E-Mail">
        <el-input v-model="request.email" size="small"></el-input>
      </el-form-item>
      <el-form-item prop="company" label="Company Name">
        <el-input v-model="request.company" size="small"></el-input>
      </el-form-item>

      <el-form-item prop="domain" label="Custom Subdomain" class="marketplace_multi_column">
        <el-input placeholder="Please input" size="small" v-model="request.domain">
          <template slot="append">.binalyze.io</template>
        </el-input>
      </el-form-item>

      <el-form-item prop="password" label="Password">
        <el-input size="small" v-model="request.password" show-password> </el-input>
      </el-form-item>
      <el-form-item prop="confirmpass" label="Confirm Password">
        <el-input size="small" v-model="request.confirmpass" show-password> </el-input>
      </el-form-item>

      <span class="marketplace_multi_column"
        ><FaIcon :icon="['far', 'times-circle']" class="cross-icon" v-if="!passcheck.passwordCharLimit" />
        <FaIcon :icon="['far', 'check-circle']" class="check-icon" v-else /> Password should contain at least 8
        characters</span
      >
      <span class="marketplace_multi_column"
        ><FaIcon :icon="['far', 'times-circle']" class="cross-icon" v-if="!passcheck.passwordupper" />
        <FaIcon :icon="['far', 'check-circle']" class="check-icon" v-else /> Password should contain at least 1 UPPER
        CASE character</span
      >
      <span class="marketplace_multi_column"
        ><FaIcon :icon="['far', 'times-circle']" class="cross-icon" v-if="!passcheck.passwordlower" />
        <FaIcon :icon="['far', 'check-circle']" class="check-icon" v-else /> Password should contain at least 1 lower
        case character</span
      >
      <span class="marketplace_multi_column"
        ><FaIcon :icon="['far', 'times-circle']" class="cross-icon" v-if="!passcheck.passwordspecial" />
        <FaIcon :icon="['far', 'check-circle']" class="check-icon" v-else /> Password should contain at least 1 special
        character</span
      >
      <span class="marketplace_multi_column"
        ><FaIcon :icon="['far', 'times-circle']" class="cross-icon" v-if="!passcheck.passwordnumeric" />
        <FaIcon :icon="['far', 'check-circle']" class="check-icon" v-else /> Password should contain at least 1 numeric
        character</span
      >
      <div class="marketplace_form_empty_row"></div>

      <h4 class="marketplace_multi_column bottom_spaced">Subscription</h4>
      <div class="label">Asset Count</div>
      <div class="label">Period (days)</div>
      <el-input size="small" v-model="entitlement.intValue" disabled> </el-input>
      <el-input size="small" v-model="remainingDays" disabled> </el-input>
      <div class="marketplace_form_empty_row"></div>
      <div style="padding: 10px"></div>
      <el-button type="primary" size="small" @click="submitForm('loginForm')">Create Your Instance</el-button>
    </el-form>

    <CreatingStepsForMarketplace
      v-if="startCreating"
      :licenseKey="licenseKeyForTry"
      @on-complete="removeUnloadEvent"
      class="marketplace_container"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { MarketplaceService } from '@/api/Services/Marketplace/MarketplaceService'
import { CreateCustomerMarketplaceDto } from '@/api/Services/Marketplace/dto/create-customer-marketplace.dto'
import CreatingStepsForMarketplace from '@/views/Marketplace/CreatingStepsForMarketplace.vue'

export default {
  name: 'Marketplace',

  components: {
    CreatingStepsForMarketplace,
  },

  data() {
    return {
      showError: false,
      rules: {
        name: [{ required: true, message: 'First Name is required', trigger: 'blur' }],
        surname: [{ required: true, message: 'Last Name is required', trigger: 'blur' }],
        email: [{ required: true, type: 'email', trigger: ['blur', 'change'] }],
        company: [{ required: true, message: 'Company Name is required', trigger: 'blur' }],
        domain: [{ required: true, message: 'Custom Subdomain is required', trigger: 'blur' }],
        password: [{ validator: this.passwordValidator, trigger: ['blur', 'change'] }],
        confirmpass: [{ validator: this.passwordMatchValidator, trigger: ['blur', 'change'] }],
      },
      request: {
        name: '',
        surname: '',
        email: '',
        company: '',
        domain: '',
        password: '',
        confirmpass: '',
      },
      passcheck: {
        passwordCharLimit: false,
        passwordupper: false,
        passwordlower: false,
        passwordspecial: false,
        passwordnumeric: false,
      },
      input1: '',
      customer: {},
      entitlement: {},
      remainingDays: '',
      licenseKeyForTry: '',

      newCustomer: false,
      expiredCustomer: false,
      redirectedCustomer: false,
      startCreating: false,
      waitForUs: false,
    }
  },

  methods: {
    newCustomerState() {
      this.newCustomer = true
      this.expiredCustomer = false
      this.redirectedCustomer = false
      this.startCreating = false
      this.waitForUs = false
    },

    expiredState() {
      this.newCustomer = false
      this.expiredCustomer = true
      this.redirectedCustomer = false
      this.startCreating = false
      this.waitForUs = false
    },

    redirectState() {
      this.newCustomer = false
      this.expiredCustomer = false
      this.redirectedCustomer = true
      this.startCreating = false
      this.waitForUs = false
    },

    startCreatingState() {
      this.newCustomer = false
      this.expiredCustomer = false
      this.redirectedCustomer = false
      this.startCreating = true
      this.waitForUs = false
    },

    waitForUsState() {
      this.newCustomer = false
      this.expiredCustomer = false
      this.redirectedCustomer = false
      this.startCreating = false
      this.waitForUs = true
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) {
          return false
        }

        await this.$request(
          async () => {
            const newCustomer = new CreateCustomerMarketplaceDto()
            newCustomer.lastname = this.request.surname
            newCustomer.firstname = this.request.name
            newCustomer.email = this.request.email
            newCustomer.company = this.request.company
            newCustomer.domain = this.request.domain
            newCustomer.entitlementExpiration = this.entitlement.expirationDate
            newCustomer.entitlementValue = this.entitlement.intValue
            newCustomer.marketplaceId = this.customer.customerId
            newCustomer.entitlementDimension = this.entitlement.dimension
            newCustomer.password = this.request.password
            const { data } = await MarketplaceService.createCustomerMarketplace(newCustomer)
            if (data.success) {
              await this.$request(
                async () => {
                  const secondData = await MarketplaceService.registerServer(
                    data.result.licenseKey,
                    newCustomer.password,
                  )
                  if (secondData.data.success) {
                    this.awaitFinalization(data.result.licenseKey)
                  }
                },

                null,
                (errordata) => {
                  switch (errordata.response.data.result.errorCode) {
                    case 'IR02':
                    case 'IR03':
                      this.waitForUsState()
                      break
                    case 'IR00':
                    case 'ID00':
                      this.showError = true
                      this.request.domain = ''
                      this.$message({
                        showClose: true,
                        message: 'Subdomain must be provided',
                        type: 'error',
                      })
                      break
                    case 'IR01':
                    case 'IP01':
                      this.showError = true
                      this.request.domain = ''
                      this.$message({
                        showClose: true,
                        message: 'Provided domain already registered to our system',
                        type: 'error',
                      })
                      break
                    default:
                      this.$message({
                        showClose: true,
                        message: 'There has been an unknown error. Please try again.',
                        type: 'error',
                      })
                  }
                },
              )
            }
          },

          this.$waiters.OneMarketplace.SubscriptionPage,
          (data) => {
            if (data.response) {
              if (data.response.data.message.indexOf('Email') > -1) {
                this.showError = true
                this.request.email = ''
                this.$message({
                  showClose: true,
                  message: 'Provided e-mail address is already registered to our system',
                  type: 'error',
                })
              } else if (data.response.data.message.indexOf('Domain') > -1) {
                this.showError = true
                this.request.domain = ''
                this.$message({
                  showClose: true,
                  message: 'Provided domain already registered to our system',
                  type: 'error',
                })
              } else if (data.response.data.message.indexOf('Marketplace') > -1) {
                this.showError = true
                this.request = {
                  name: '',
                  surname: '',
                  email: '',
                  company: '',
                  domain: '',
                  password: '',
                  confirmpass: '',
                }
                this.$message({
                  showClose: true,
                  message: 'Marketplace customer has already been created.',
                  type: 'error',
                })
              }
            }
          },
        )
      })
    },

    awaitFinalization(licenseKey) {
      this.startCreatingState()
      this.licenseKeyForTry = licenseKey
      this.newCustomer = false
    },

    removeUnloadEvent() {},

    getRemainingDays(date) {
      const expiration = dayjs(date)
      this.remainingDays = expiration.diff(dayjs(), 'days')
    },

    passwordValidator(rule, value, callback) {
      const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,256}$/
      this.checkIndividualConstraints(value)
      if (value === '') {
        callback(new Error('Please enter a password'))
      } else if (!passwordRegex.test(value)) {
        callback(new Error('Password requirements not met'))
      } else {
        callback()
      }
    },

    passwordMatchValidator(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.request.password) {
        callback(new Error("Two inputs don't match!"))
      } else {
        callback()
      }
    },

    checkIndividualConstraints(text) {
      if (text.length > 7 && text.length < 256) {
        this.passcheck.passwordCharLimit = true
      } else {
        this.passcheck.passwordCharLimit = false
      }
      const digitRegex = /^(?=.*[0-9])/
      if (digitRegex.test(text)) {
        this.passcheck.passwordnumeric = true
      } else {
        this.passcheck.passwordnumeric = false
      }
      const lowerRegex = /^(?=.*[a-z])/
      if (lowerRegex.test(text)) {
        this.passcheck.passwordlower = true
      } else {
        this.passcheck.passwordlower = false
      }
      const upperRegex = /^(?=.*[A-Z])/
      if (upperRegex.test(text)) {
        this.passcheck.passwordupper = true
      } else {
        this.passcheck.passwordupper = false
      }
      const specialRegex = /^(?=.*\W)/
      if (specialRegex.test(text)) {
        this.passcheck.passwordspecial = true
      } else {
        this.passcheck.passwordspecial = false
      }
    },
  },

  async mounted() {
    const onSuccess = async () => {
      const customerData = await MarketplaceService.getCustomer(this.$route.query.token)
      this.customer = customerData.data.result
      if (this.customer.entitlements.length === 0) {
        this.expiredState()
      } else if (this.customer.licenseKey) {
        this.awaitFinalization(this.customer.licenseKey)
      } else {
        this.entitlement = this.customer.entitlements[0]
        this.getRemainingDays(this.entitlement.expirationDate)
        this.newCustomerState()
      }
    }
    const onError = () => {
      this.expiredState()
    }
    await this.$request(onSuccess, this.$waiters.OneMarketplace.SubscriptionPage, onError)
  },
}
</script>

<style lang="scss">
.marketplace_container {
  background-color: white;
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 20px;
  grid-row-gap: 4px;
  padding: 20px;
  width: 460px;
  margin: auto;
}
.marketplace_multi_column {
  grid-column-start: 1;
  grid-column-end: 3;
}
.center {
  text-align: center;
}
.bottom_spaced {
}
.marketplace_form_empty_row {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 10px;
}
.market-views-background {
  background: #f4f6f8;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-form-item__content {
  line-height: 0px !important;
  margin-bottom: -5px;
}

.el-form-item__label {
  margin-bottom: -8px;
}
.cross-icon {
  color: tomato;
  -webkit-font-smoothing: antialiased;
}
.check-icon {
  color: mediumseagreen;
  -webkit-font-smoothing: antialiased;
}

.cross-icon-for-nosubs {
  color: white;
  background: #e74c3c;
  border-radius: 50%;
  width: 100px !important;
  height: 100px;
  margin: auto;
  grid-column-start: 1;
  grid-column-end: 3;
}

.cross-icon-for-nosubs {
  color: white;
  background: rgb(41, 147, 38);
  border: 10px solid rgb(41, 147, 38);
  width: 100px !important;
  height: 100px;
  margin: auto;
  grid-column-start: 1;
  grid-column-end: 3;
}
</style>
