export const InputMixin = {
  data() {
    return {
      isRequired: false,
    }
  },

  mounted() {
    const validateDirective = this._vnode?.parent?.data?.directives?.find((d) => d.name === 'validate')
    if (typeof validateDirective?.value === 'string') {
      this.isRequired = validateDirective?.value?.includes?.('required')
    } else if (typeof validateDirective?.value === 'object') {
      this.isRequired = validateDirective?.value?.['required']
    }
  },

  computed: {
    getError() {
      if (this.errors) {
        const error = this.errors.items.find((e) => e.field == this.name)
        return this.errorMsg || error?.msg
      }
      return this.errorMsg
    },
  },
}
