<template>
  <div class="licenses-background">
    <el-container>
      <el-header class="license-navigation-header">
        <RouterLink to="/" class="binalyze-logo">
          <VBinalyzeLogo class="mr-4" width="80" />
        </RouterLink>

        <span class="license-header-separator mr-4"></span>

        <RouterLink to="/license/licenses">
          <span
            class="mr-4"
            v-bind:class="{
              licenseActiveRoute: $route.name === 'LicenseLicenses',
              licenseLink: $route.name !== 'LicenseLicenses',
            }"
          >
            <span class="mr-1"><fa-icon icon="table" /></span>Licenses</span
          >
        </RouterLink>
        <RouterLink to="/license/versions" v-if="hasPermission('versions-manage')">
          <span
            class="mr-4"
            v-bind:class="{
              licenseActiveRoute: $route.name === 'LicenseVersions',
              licenseLink: $route.name !== 'LicenseVersions',
            }"
          >
            <span class="mr-1"> <fa-icon icon="tasks" /> </span> Versions</span
          >
        </RouterLink>
        <RouterLink to="/license/tags">
          <span
            class="mr-4"
            v-bind:class="{
              licenseActiveRoute: $route.name === 'LicenseTags',
              licenseLink: $route.name !== 'LicenseTags',
            }"
          >
            <span class="mr-1"> <fa-icon icon="tags" /> </span> Tags</span
          >
        </RouterLink>

        <RouterLink to="/license/users" v-if="hasPermission('users-manage')">
          <span
            class="mr-4"
            v-bind:class="{
              licenseActiveRoute: $route.name === 'Users',
              licenseLink: $route.name !== 'Users',
            }"
          >
            <span class="mr-1"> <fa-icon icon="users" /> </span> Users</span
          >
        </RouterLink>

        <RouterLink to="/license/tokens" v-if="hasPermission('tokens-manage')">
          <span
            class="mr-4"
            v-bind:class="{
              licenseActiveRoute: $route.name === 'Tokens',
              licenseLink: $route.name !== 'Tokens',
            }"
          >
            <span class="mr-1"> <fa-icon icon="key" /> </span> API Tokens</span
          >
        </RouterLink>

        <RouterLink to="/license/settings" v-if="hasPermission('settings-manage')">
          <span class="mr-4">
            <span class="mr-1"> <fa-icon icon="cogs" /> </span> Settings
          </span>
        </RouterLink>

        <a class="mr-4 logoutLink" style="margin-left: auto" @click="logout">
          <span class="mr-1"> <fa-icon icon="sign-out-alt" /> </span> Logout</a
        >
      </el-header>
    </el-container>
    <el-container class="license-pages-templates">
      <RouterView />
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'LicenseTop',

  methods: {
    logout() {
      this.$store.commit('logout')
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss">
.license-navigation-header {
  background-color: whitesmoke;
  color: #333;
  line-height: 60px;
  text-align: left;
}

.license-header-separator {
  border-left: 1px solid $color-dark-70;
  height: 45px;
}

.license-pages-templates {
  padding: 20px;
  color: $color-dark;
}

.licenses-background {
  background-color: $color-light-gray;
}

.licenseActiveRoute {
  color: mix($color-dark, $color-primary-10, 50%);
}

.licenseLink {
  color: $color-dark-90;
}

.logoutLink {
  margin-left: auto;
  float: right;
}
</style>
