<template>
  <div :class="`v-alert --${type}`" role="alert">
    <span class="fw-semibold" v-text="title" />
    <span class="fw-medium mt-1" v-text="message" />
  </div>
</template>

<script>
export default {
  name: 'VAlert',

  props: {
    title: String,
    message: String,
    type: {
      type: String,
      default: 'error',
      validator: (val) => ['error', 'warning'].includes(val),
    },
  },
}
</script>

<style lang="scss">
.v-alert {
  padding: 12px 20px;

  span {
    display: block;
  }

  &.--warning {
    border-left: 3px solid $color-orange;
    background-color: $color-orange-10;
    color: $color-orange;
  }

  &.--error {
    border-left: 3px solid $color-red;
    background-color: $color-red-10;
    color: $color-red;
  }
}
</style>
