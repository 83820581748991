import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SignIn from '../views/Auth/SignIn.vue'
import CallbackAuthTornado from '../views/Tornado/Auth/Callback.vue'
import LicenseTop from '../views/License/LicenseTop.vue'
import Search from '../views/License/children/Search.vue'
import Licenses from '../views/License/children/Licenses.vue'
import AuditLogs from '../views/License/children/AuditLogs.vue'
import Versions from '../views/License/children/Versions.vue'
import Tags from '@/views/License/children/Tags.vue'
import { checkAuth } from '@/router/beforeEach'
import Users from '@/views/License/children/Users.vue'
import Tokens from '@/views/License/children/Tokens.vue'
import Marketplace from '@/views/Marketplace/Marketplace.vue'
import Settings from '@/views/License/children/Settings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { authRequired: true },
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
  },
  {
    path: '/license',
    name: 'LicenseTop',
    component: LicenseTop,
    redirect: '/license/licenses',
    children: [
      {
        path: 'search',
        component: Search,
        name: 'LicenseSearch',
        meta: { dontShowHeader: true, authRequired: true },
      },
      {
        path: 'versions',
        component: Versions,
        name: 'LicenseVersions',
        meta: { dontShowHeader: true, authRequired: true },
      },
      {
        path: 'tags',
        component: Tags,
        name: 'LicenseTags',
        meta: { dontShowHeader: true, authRequired: true },
      },
      {
        path: 'audit',
        component: AuditLogs,
        name: 'LicenseAudit',
        meta: { dontShowHeader: true },
      },
      {
        path: 'licenses',
        component: Licenses,
        name: 'LicenseLicenses',
        meta: { dontShowHeader: true, authRequired: true },
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: { dontShowHeader: true, authRequired: true },
      },
      {
        path: 'tokens',
        name: 'Tokens',
        component: Tokens,
        meta: { dontShowHeader: true, authRequired: true },
      },
      {
        path: 'settings',
        name: 'LicenseSettings',
        component: Settings,
        meta: { dontShowHeader: true, authRequired: true },
      },
    ],
  },
  {
    path: '/auth/sign-in',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/tornado/auth/callback',
    name: 'CallbackAuthTornado',
    component: CallbackAuthTornado,
    meta: { authRequired: false },
  },
  {
    path: '/confirm-customer',
    name: 'ConfirmCustomer',
    component: () => import(/* webpackChunkName: "customer" */ '@/views/ConfirmCustomer.vue'),
  },
  {
    path: '/create-instance',
    name: 'CreateAIRInstance',
    component: () => import(/* webpackChunkName: "customer" */ '@/views/CreateInstance/CreateAIRInstance.vue'),
  },
  {
    path: '/internal',
    name: 'Internal',
    redirect: '/internal/create-instance',
    component: () => import(/* webpackChunkName: "internal" */ '@/views/Internal/Internal.vue'),
    children: [
      {
        path: 'create-instance',
        name: 'InternalCreateAIRInstance',
        component: () =>
          import(/* webpackChunkName: "internal" */ '@/views/Internal/CreateAIRInstance/CreateAIRInstance.vue'),
      },
      {
        path: 'one-customers',
        name: 'InternalOneCustomers',
        component: () => import(/* webpackChunkName: "internal" */ '@/views/Internal/InternalOneCustomers.vue'),
      },
      {
        path: 'air-customers',
        name: 'InternalAIRCustomers',
        component: () =>
          import(/* webpackChunkName: "internal" */ '@/views/Internal/InternalAIRCustomers/InternalAIRCustomers.vue'),
      },
      {
        path: 'air-customers/:id',
        name: 'InternalAIRCustomerDetails',
        component: () =>
          import(
            /* webpackChunkName: "internal" */ '@/views/Internal/InternalAIRCustomers/InternalAIRCustomerDetails.vue'
          ),
      },
      {
        path: 'air-customers/:id/case-activities',
        name: 'InternalAIRCaseActivities',
        component: () =>
          import(
            /* webpackChunkName: "internal" */ '@/views/Internal/InternalAIRCustomers/InternalAIRCaseActivities.vue'
          ),
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})
router.beforeEach(checkAuth(router))
export default router
