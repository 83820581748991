<template>
  <div class="license-devices-view">
    <el-table :data="quotas" style="width: 100%" v-loading="$wait.is($waiters.License.Quotas)">
      <el-table-column
        width="155"
        v-if="licenseDetails.type === LicenseTypeEnum.Enterprise"
        label="Total Asset Capacity"
        prop="totalClientCapacity"
      />
      <el-table-column
        width="120"
        v-if="licenseDetails.type === LicenseTypeEnum.Enterprise"
        label="Server Capacity"
        prop="serverCapacity"
      />
      <el-table-column
        v-if="licenseDetails.type === LicenseTypeEnum.IR"
        label="Case Capacity"
        width="120"
        prop="caseCapacity"
      />
      <el-table-column
        v-if="licenseDetails.type === LicenseTypeEnum.IR"
        label="Case Lifetime"
        width="120"
        prop="caseLifetime"
      />
      <el-table-column label="Activation Date">
        <template slot-scope="props">
          {{ getFormattedDate(props.row.activationDate) }}
        </template>
      </el-table-column>
      <el-table-column label="Expiration Date">
        <template slot-scope="props">
          {{ getFormattedDate(props.row.expirationDate) }}
        </template>
      </el-table-column>
      <el-table-column label="Status" width="60">
        <template slot-scope="props">
          <FaIcon
            :class="props.row.status === 1 ? 'c-green' : 'c-red'"
            :icon="props.row.status === 1 ? 'check' : 'times'"
          />
        </template>
      </el-table-column>
      <el-table-column label="Created By" prop="createdBy" width="100" />
      <el-table-column label="Created At">
        <template slot-scope="props">
          {{ getFormattedDate(props.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column label="Updated At">
        <template slot-scope="props">
          {{ getFormattedDate(props.row.updatedAt) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Operations" v-if="hasPermission('license-manage')">
        <template slot-scope="props">
          <div class="operations">
            <el-button type="text" @click="onClickChangePeriod(props.row)" size="small">Change Period</el-button>
            <el-button
              type="text"
              @click="onClickChangeCapacity(props.row)"
              v-if="licenseDetails.type === LicenseTypeEnum.Enterprise"
              style="margin-left: 0"
              size="small"
              >Change Capacity</el-button
            >
            <div>
              <el-popconfirm title="Are you sure" @confirm="onClickEnable(props.row)">
                <template #reference>
                  <el-button type="text" size="small" :loading="$wait.is($waiters.License.QuotaStatusUpdate)">
                    {{ props.row.status === 1 ? 'Disable' : 'Enable' }}
                  </el-button>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <ChangeQuotaPeriodPanel
      :show="showChangeQuotaPeriodPanel"
      :quota="selectedQuota"
      :license="licenseDetails"
      @close="showChangeQuotaPeriodPanel = false"
      @reload="onReload"
    />
    <ChangeQuotaCapacityPanel
      :show="showChangeCapacityPanel"
      :license="licenseDetails"
      :quota="selectedQuota"
      @close="showChangeCapacityPanel = false"
      @reload="onReload"
    />
  </div>
</template>
<script>
import * as $waiters from '@/constants/Waiters'
import { DateFormats, UtilityService } from '@/api/Services/License/UtilityService'
import { LicenseQuotasService } from '@/api/Services/License/LicenseQuotasService'
import { LicenseTypeEnum } from '@/api/Services/License/enums/license-type.enum'
import ChangeQuotaPeriodPanel from '@/views/License/children/subComp/licenseViewTabs/quotas/changeQuotaPeriodPanel.vue'
import ChangeQuotaCapacityPanel from '@/views/License/children/subComp/licenseViewTabs/quotas/changeQuotaCapacityPanel.vue'

export default {
  name: 'LicenseQuotasInfo',

  components: {
    ChangeQuotaCapacityPanel,
    ChangeQuotaPeriodPanel,
  },

  props: {
    licenseDetails: Object,
  },

  computed: {
    LicenseTypeEnum() {
      return LicenseTypeEnum
    },

    $waiters() {
      return $waiters
    },
  },

  data() {
    return {
      quotas: [],
      totalEntityCount: 0,
      selectedQuota: null,
      showChangeQuotaPeriodPanel: false,
      showChangeCapacityPanel: false,
    }
  },

  methods: {
    getQuotasDetails() {
      this.$request(async () => {
        const { data } = await LicenseQuotasService.get(this.licenseDetails.key, this.licenseDetails.type)

        if (data.success) {
          this.quotas = data.result
          this.totalEntityCount = data.result?.length
        }
      }, this.$waiters.License.Quotas)
    },

    getFormattedDate(date) {
      return UtilityService.getFormattedDate(date, DateFormats.MINUTES)
    },

    onClickChangePeriod(quota) {
      this.selectedQuota = quota
      this.showChangeQuotaPeriodPanel = true
    },

    onClickChangeCapacity(quota) {
      this.selectedQuota = quota
      this.showChangeCapacityPanel = true
    },

    onReload() {
      this.getQuotasDetails()
      this.$emit('reload')
    },

    onClickEnable(quota) {
      const onSuccess = async () => {
        const payload = {
          status: quota.status === 1 ? 0 : 1,
          licenseType: this.licenseDetails.type,
        }

        await LicenseQuotasService.updateStatus(payload, quota.id)
        this.$emit('reload')
      }

      this.$request(onSuccess, this.$waiters.License.QuotaStatusUpdate)
    },
  },

  watch: {
    licenseDetails() {
      this.getQuotasDetails()
    },
  },
}
</script>

<style lang="scss" scoped>
.operations {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.el-button {
  padding: 0px;
  align-self: flex-start;
}
</style>
