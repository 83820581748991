export enum LicenseTypeEnum {
  Enterprise = 1,
  IR = 2,
  MSS = 3,
}
export class LicenseTypeEnumUtils {
  static getLicenseTypesForDropdown() {
    return [
      {
        value: LicenseTypeEnum.Enterprise,
        label: 'Enterprise',
        disabled: false,
      },
      {
        value: LicenseTypeEnum.IR,
        label: 'IR (FIS)',
        disabled: false,
      },
      {
        value: LicenseTypeEnum.MSS,
        label: 'MSS',
        disabled: true,
      },
    ]
  }

  static getDefaultLicenseType() {
    return LicenseTypeEnum.Enterprise
  }
}
