<template>
  <el-container class="tags-page">
    <el-header class="tags-header">
      <div class="tags-count">
        <h3 v-text="$t('Users')" />
      </div>
    </el-header>

    <VCard>
      <el-table
        :data="tableData"
        stripe
        empty-text="There are no tags"
        class="w-100"
        v-loading="$wait.is($waiters.License.Users)"
        height="77vh"
      >
        <!-- Val-->
        <el-table-column label="email" min-width="100">
          <template v-slot="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <el-table-column label="Last Activity" min-width="100">
          <template v-slot="scope">
            {{ getFormattedDate(scope.row.lastlogin) }}
          </template>
        </el-table-column>
        <el-table-column label="Privileges" min-width="100">
          <template v-slot="scope">
            {{ convertPrivileges(scope.row.privileges) }}
          </template>
        </el-table-column>
        <el-table-column label="Actions" min-width="100">
          <template v-slot="scope">
            <div class="operations">
              <el-button type="text" @click="openEditUserDetail(scope.row)" size="small">Edit</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handlePaginationPageChange"
        @size-change="handlePaginationSizeChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="sizes, prev, pager, next, jumper"
        :page-sizes="[25, 50, 100]"
        :total="totalEntityCount"
      >
      </el-pagination>
    </VCard>
    <UserEditPanel
      :panelDisplayStatus="updateUserPanelVisible"
      @close="updateUserPanelVisible = false"
      :user="selectedUser"
      :show="updateUserPanelVisible"
      @reload="fetchUsers(pageSize, currentPage)"
    />
  </el-container>
</template>
<script>
import { UserService } from '@/api/Services/UserService'
import { PagingUserRequestDto } from '@/api/Services/License/dto/request/paging-user.request.dto'
import { DateFormats, UtilityService } from '@/api/Services/License/UtilityService'
import { PrivilegeEnumUtils } from '@/api/Services/enums/PrivilegeEnum'
import UserEditPanel from '@/views/License/children/subComp/userEditPanel.vue'

export default {
  components: {
    UserEditPanel,
  },

  data() {
    return {
      tableData: [],
      totalEntityCount: 0,
      pageSize: 25,
      currentPage: 0,
      updateUserPanelVisible: false,
      selectedUser: null,
    }
  },

  methods: {
    fetchUsers(pageSize, selectedPage) {
      this.$request(async () => {
        const request = new PagingUserRequestDto()
        request.pageNumber = selectedPage
        request.pageSize = pageSize
        const { data } = await UserService.getUsers(request)
        if (data.success) {
          this.tableData = data.result.entities
          this.totalEntityCount = data.result.totalEntityCount
        }
      }, this.$waiters.License.License)
    },

    getFormattedDate(date) {
      let formattedDate = UtilityService.getFormattedDate(date, DateFormats.SECONDS)
      if (formattedDate === 'Invalid Date') formattedDate = '—'
      return formattedDate
    },

    handlePaginationPageChange(page) {
      this.fetchUsers(this.pageSize, page)
    },

    handlePaginationSizeChange(size) {
      this.fetchUsers(size, this.currentPage)
    },

    convertPrivileges(privArray) {
      let str = ''
      privArray.forEach((p) => {
        str = str + ', ' + PrivilegeEnumUtils.privilegeToString(p)
      })
      str = str.substring(2)
      return str
    },

    openEditUserDetail(user) {
      this.selectedUser = user
      this.updateUserPanelVisible = true
    },
  },

  mounted() {
    this.fetchUsers(this.pageSize, 1)
  },
}
</script>
