import dayjs from 'dayjs'

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Make safe enum
 * @param {object} obj
 * @returns {object}
 */
export const makeEnum = (obj) => {
  Object.freeze(obj)

  const handler = {
    get(target, property) {
      if (property in target) {
        return target[property]
      }
      throw new Error(`Property '${property}' is not defined`)
    },
  }
  return new Proxy(obj, handler)
}

/**
 * Deep search in object
 * @param {object} item
 * @param {RegExp} regexQuery
 * @returns {boolean}
 */
export const searchInObject = (item, regexQuery) => {
  return Object.entries(item).some(([, value]) => {
    if (typeof value === 'object') {
      return searchInObject(value, regexQuery)
    }
    return value?.toString().search(regexQuery) !== -1
  })
}

/**
 * @param {number} days
 * @param {string} unit
 * @returns {string}
 */
export const getDate = (days = 0, unit = 'day') => dayjs.utc().add(days, unit).toISOString()

/**
 * @param {string} value
 */
export const slugify = (value) => require('slugify').default(value).toLowerCase()

/**
 * Format Number
 * @param {string|number} number
 * @param {number} minimumFractionDigits between 0 and 20
 * @param {number} maximumFractionDigits between 0 and 20
 * @returns {string}
 */
export const formatNumber = (number, minimumFractionDigits = 0, maximumFractionDigits = 2) => {
  return Number(number).toLocaleString(undefined, {
    minimumFractionDigits: Math.min(minimumFractionDigits, 20),
    maximumFractionDigits: Math.min(maximumFractionDigits, 20),
  })
}

/**
 * @param {object} data
 */
export const json2FormData = (data) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }
  return formData
}

/**
 * @param {Blob} file
 */
export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

/**
 * @param {Blob} file
 */
export const readFileAsBinaryString = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

/**
 * Download File
 * @param {string} filename
 * @param {string} content
 * @param {string} type
 */
export const downloadFile = (filename, content, type = 'text/plain;charset=utf-8') => {
  let blob = new Blob([content], { type })
  let anchor = document.createElement('a')

  anchor.download = filename
  anchor.href = (window.webkitURL || window.URL).createObjectURL(blob)
  anchor.dataset.downloadurl = [type, anchor.download, anchor.href].join(':')
  anchor.click()
  anchor.remove()
}
