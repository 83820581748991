<template>
  <div class="v-check-loader" :style="style">
    <VCircleLoader v-if="loading" :border="border" :color="color" :size="size" />
    <FaIcon v-else :icon="icon" class="check-icon" :style="checkStyle" />
  </div>
</template>

<script>
export default {
  name: 'VCheckLoader',

  props: {
    loading: Boolean,
    size: String,
    border: String,
    color: String,
    icon: {
      type: String,
      default: 'check',
    },
  },

  computed: {
    style() {
      const style = { width: this.size, height: this.size }
      if (!this.loading) {
        style.backgroundColor = this.color
      }
      return style
    },

    checkStyle() {
      return {
        fontSize: `calc(${this.size} / 2)`,
        color: this.loading ? this.color : '#fff',
      }
    },
  },
}
</script>

<style lang="scss">
.v-check-loader {
  position: relative;
  border-radius: 50%;

  & > * {
    position: absolute;
  }

  .check-icon {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
}
</style>
