import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { LicenseEnvironmentEnum } from '../../enums/license-environment.enum'
import { LicenseTypeEnum } from '../../enums/license-type.enum'

export class LicenseCreateRequestUsingHubspotDto {
  @IsNotEmpty()
  @IsEnum(LicenseTypeEnum)
  type: LicenseTypeEnum

  @IsBoolean()
  @IsNotEmpty()
  isOffline: boolean

  @IsNotEmpty()
  @IsEnum(LicenseTypeEnum)
  environment: LicenseEnvironmentEnum

  @IsOptional()
  @IsString()
  notes: string

  @IsNotEmpty()
  @IsNumber()
  hubspotContactId: number

  @IsNotEmpty()
  @IsString()
  hubspotContactEmail: string

  @IsOptional()
  @IsString()
  hubspotContactFirstname: string

  @IsOptional()
  @IsString()
  hubspotContactLastname: string

  @IsNotEmpty()
  @IsNumber()
  hubspotCompanyId: number

  @IsNotEmpty()
  @IsString()
  hubspotCompanyName: string

  @IsOptional()
  @IsArray()
  @IsString({ each: true })
  tags: string[]
}
