<template>
  <div>
    <p v-if="loading">Loading...</p>
    <p v-if="error">{{ error }}</p>
    <p v-if="success">Authentication successful!</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TornadoAuthCallback',
  data() {
    return {
      loading: true,
      error: null,
      success: false,
    };
  },
  async mounted() {
    try {
      let { type, tornadoBaseUrl, wails, encodedTokens } = this.parseUrlParams();
     
      wails = wails === 'true';  // convert to boolean
      if (wails) {
        tornadoBaseUrl = "wails://wails"
      }

      if (!tornadoBaseUrl) {
        this.redirectToAuth();
        return;
      }

      if (!encodedTokens) {
        throw new Error('Token information not found');
      }


      this.redirectToTornado(tornadoBaseUrl, encodedTokens, type);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    parseUrlParams() {
      const queryString = window.location.hash.split('?')[1];
      const params = new URLSearchParams(queryString);
      return {
        type: params.get('type'),
        wails: params.get('wails'),
        tornadoBaseUrl: params.get('redirectUrl'),
        encodedTokens: params.get('tokens')
      };
    },

    redirectToAuth() {
      this.success = false;
      this.$router.push('/tornado/auth');
    },




    redirectToTornado(tornadoBaseUrl, encodedTokens, type) {
      const redirectMap = {
        google: '/parselets/gmail/',
        o365: '/parselets/office-365/'
      };

      if (!redirectMap[type]) {
        this.handleError(new Error('Invalid redirect type: ' + type));
        return;
      }

      const targetRedirectUrl = new URL(redirectMap[type], tornadoBaseUrl);
      targetRedirectUrl.searchParams.append('encodedTokens', encodedTokens);
      window.location.href = targetRedirectUrl.toString();
    },

    handleError(error) {
      this.error = 'An error occurred: ' + error.message;
    }
  }
};
</script>

