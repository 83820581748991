import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import en from './Langs/en.json'

// example: en-US
const [localLanguage] = (navigator.language || navigator.userLanguage || '').split('-')

export default new VueI18n({
  silentTranslationWarn: true,
  locale: localLanguage || 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
})
