<template>
  <el-container class="versions-page">
    <el-header class="versions-header">
      <div class="versions-count">
        <h3 v-text="`${$t('Versions')}`" />
      </div>
      <div class="versions-actions">
        <el-button
          type="primary"
          v-on:click="toggleNewVersionDialog('create')"
          primary
          icon="el-icon-circle-plus-outline"
        >
          Create Version
        </el-button>
      </div>
    </el-header>

    <VCard>
      <el-table
        :data="tableData"
        stripe
        empty-text="There are no versions"
        class="w-100"
        v-loading="$wait.is($waiters.License.Versions)"
        height="77vh"
      >
        <!-- Version-->
        <el-table-column prop="version" label="Version" min-width="100">
          <template v-slot="scope">
            <a v-text="scope.row.version" @click="openVersionDetail(scope.row)" />
          </template>
        </el-table-column>

        <!-- ReleasedAt-->
        <el-table-column prop="releasedAt" label="Released at" :min-width="45">
          <template v-slot="scope">
            <span v-text="getFormattedDate(scope.row.releasedAt)"></span>
          </template>
        </el-table-column>

        <!-- IsActive-->
        <el-table-column prop="isActive" label="Active" min-width="35">
          <template v-slot="scope">
            <FaIcon v-if="scope.row.isActive" class="form-icon c-dark-70" icon="check" />
            <FaIcon v-else class="form-icon" icon="times" />
          </template>
        </el-table-column>

        <!-- Created By-->
        <el-table-column prop="createdBy" label="Created by" min-width="50">
          <template v-slot="scope">
            <FaIcon class="form-icon mr-2" icon="user" />
            <span v-text="scope.row.createdBy"></span>
          </template>
        </el-table-column>
      </el-table>
    </VCard>

    <NewVersionComponent
      v-if="newVersionPanelVisible"
      :panelDisplayStatus="newVersionPanelVisible"
      @displayStatus="toggleNewVersionDialog"
      :update-data="selectedVersion"
      @reload="fetchVersions"
    />
  </el-container>
</template>

<script>
import { VersionsService } from '@/api/Services/License/VersionsService'
import NewVersionComponent from '@/views/License/children/subComp/newVersionPanel.vue'
import { UtilityService } from '@/api/Services/License/UtilityService'

export default {
  name: 'Versions',

  components: {
    NewVersionComponent,
  },

  data() {
    return {
      tableData: [],
      totalEntityCount: 0,
      newVersionPanelVisible: false,
      selectedVersion: null,
    }
  },

  methods: {
    fetchVersions() {
      this.$request(async () => {
        const { data } = await VersionsService.getAll()
        if (data.success) {
          this.tableData = data.result
          this.totalEntityCount = data.result?.length
        }
      }, this.$waiters.License.Versions)
    },

    toggleNewVersionDialog(type) {
      if (type === 'create') {
        this.selectedVersion = null
      }
      this.newVersionPanelVisible = !this.newVersionPanelVisible
    },

    getFormattedDate(date) {
      return UtilityService.getFormattedDate(date, UtilityService.DATE)
    },

    openVersionDetail(data) {
      this.selectedVersion = data
      this.toggleNewVersionDialog()
    },
  },

  created() {
    this.fetchVersions()
  },
}
</script>

<style lang="scss">
.versions-header {
  height: auto !important;
  padding: 0 2px !important;
  margin: 0 0 10px 0 !important;
  display: flex !important;
  align-items: baseline;
}

.versions-count {
  flex: 1;
}
</style>
