<template>
  <div class="v-switch" :class="clazz">
    <input :id="_uid" class="sr-only" type="checkbox" v-bind="$attrs" :checked="value" v-on="inputListeners" />

    <!-- Loading -->
    <div v-if="loading" class="v-switch__loading">
      <FaIcon spin :class="textClass" icon="circle-notch" style="pointer-events: none" />
    </div>
    <!-- Switch -->
    <div v-else class="v-switch__body" @click.stop="onClick" :class="textClass">
      <span class="v-switch__thumb" />
    </div>

    <!-- Label -->
    <slot>
      {{ text }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'VSwitch',

  props: {
    text: String,
    value: Boolean,
    click: Function,
    reverse: Boolean,
    disabled: Boolean,
    loading: Boolean,
    size: {
      type: String,
      default: 'medium',
      validator: (v) => ['small', 'medium'].includes(v),
    },
  },

  methods: {
    onClick() {
      if (this.disabled) return

      if (typeof this.click === 'function') {
        this.click()
      } else {
        this.$emit('input', !this.value)
        this.$emit('change', !this.value)
      }
    },
  },

  computed: {
    clazz() {
      return [
        `--${this.size}`,
        {
          '--checked': this.value,
          '--disabled': this.disabled,
          'flex-row-reverse': this.reverse,
        },
      ]
    },

    textClass() {
      return this.reverse ? 'ml-3' : this.text ? 'mr-3' : null
    },

    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: (event) => this.$emit('input', event.target.checked),
      })
    },
  },
}
</script>

<style lang="scss">
$width: 36px;
$height: 18px;
$thumb-size: 18px;

$small-width: 32px;
$small-height: 16px;
$small-thumb-size: 16px;

$diff: math.div($thumb-size - $height, 2);

.v-switch {
  display: flex;
  user-select: none;
  align-items: center;
  color: $color-dark;
  font-weight: $font-weight-medium;

  &__body {
    margin-top: -1px;
  }

  &__loading {
    width: $width;
    text-align: center;
    font-size: $height;
    color: $color-green;
  }

  &__thumb {
    position: relative;
    display: block;
    width: $width;
    height: $height;
    border-radius: $radius;
    background-color: $color-dark-10;
    @include transition(120ms);
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -$diff;
      width: 1em;
      height: 1em;
      @include transition(inherit);
      border-radius: $radius;
      font-size: $thumb-size;
      background-color: #fff;
      transform: translateY(-50%);
      border: 1px solid $color-dark-20;
      @include boxShadow(0 2px 4px 0 rgba(0, 0, 0, 0.05));
    }
  }

  &.--small {
    font-size: $font-size-small;
  }

  &.--small &__thumb {
    width: $small-width;
    height: $small-height;

    &::before {
      font-size: $small-thumb-size;
    }
  }

  &.--disabled &__thumb {
    cursor: no-drop;
    opacity: 0.6;
  }

  &.--checked &__thumb {
    border-color: transparent;
    background-color: $color-green;

    &::before {
      left: $width - $thumb-size + $diff;
    }
  }
}
</style>
