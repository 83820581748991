<template xmlns="http://www.w3.org/1999/html">
  <div class="license-general-info-main-grid">
    <div class="license-general-headers">
      <span class="el-dialog__title license-filter-header-text">Detalis</span>
    </div>
    <div class="license-general-headers">
      <span class="el-dialog__title license-filter-header-text">Customer</span>
    </div>
    <div class="license-general-info-sub-grid">
      <span class="license-general-input-label">Environment</span>
      <span v-if="!toggleEnvironmentChange"> {{ LicenseEnvironmentEnum[license.environment] }}</span>
      <el-select
        v-if="toggleEnvironmentChange"
        v-model="tempEnv"
        placeholder="Select"
        size="mini"
        @change="updateLicenseEnv()"
        v-loading="$wait.is($waiters.License.LicensesUpdate)"
      >
        <el-option
          v-for="item in Object.values(environments)"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <span>
        <!--<button v-if="hasPermission('license-manage')" class="license-edit-tags-action" @click="onClickChangeEnv()">
          <i class="el-icon-edit" v-loading="$wait.is($waiters.License.LicensesUpdate)"></i></button
      >-->
      </span>
      <span class="license-general-input-label">Version</span>
      <span> {{ license.version }}</span>
      <span></span>
      <span class="license-general-input-label">Server Capacity</span>
      <span>
        {{ license.serversInUse }}/{{ license.activeServerCapacity ? license.activeServerCapacity : '&infin;' }}</span
      >
      <span></span>
      <span class="license-general-input-label">Asset Capacity</span>
      <span>
        {{ license.totalClientsInUse }}/{{
          license.activeTotalClientCapacity ? license.activeTotalClientCapacity : '&infin;'
        }}</span
      >
      <span></span>
      <span class="license-general-input-label">Is Offline</span>

      <span> {{ license.isOffline ? 'true' : 'false' }}</span>
      <span></span>
      <span class="license-general-flex" style="gap: 10px">
        <span class="license-general-input-label"> Tags </span>
      </span>

      <div class="license-general-flex">
        <div class="license-general-flex" style="gap: 3px" v-for="tag in getIndividualTags(license.tags)">
          <span class="license-general-tags-span">{{ tag.val }}</span>
        </div>
      </div>
      <span>
        <button v-if="hasPermission('license-manage')" class="license-edit-tags-action" @click="onClickChangeTags()">
          <i class="el-icon-edit" v-loading="$wait.is($waiters.License.LicensesUpdate)"></i></button
      ></span>

      <span class="license-general-input-label">Created On</span>
      <span> {{ getFormattedDate(license.createdAt) }}</span>
      <span></span>

      <span class="license-general-input-label">Start Date</span>
      <span> {{ getFormattedDate(license.activatedAt) }}</span>
      <span></span>

      <span class="license-general-input-label">Expiration Date</span>
      <span> {{ getFormattedDate(license.expiredAt) }}</span>
      <span></span>

      <span class="license-general-input-label">Activated On</span>
      <span>
        {{
          getFormattedDate(license.firstHeartbeat) === 'Invalid Date'
            ? 'Not Activated Yet'
            : getFormattedDate(license.firstHeartbeat)
        }}</span
      >
      <span></span>
    </div>
    <div class="license-general-customer-info-sub-grid">
      <span class="license-general-input-label">Name</span>
      <span>{{ license.firstName + ' ' + license.lastName }}</span>

      <span class="license-general-input-label">Email</span>
      <span>{{ license.email }}</span>

      <span class="license-general-input-label">Company</span>
      <span>{{ license.companyName }}</span>

      <span class="license-general-input-label">Country</span>
      <span>{{ getCountryFromCode(license.countryCode) }}</span>

      <div class="license-general-info-notes-header" style="grid-column: 1/-1">
        <span class="el-dialog__title license-filter-header-text">Notes</span>
      </div>
      <div class="license-general-info-notes">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="Please input"
          v-model="tempNotes"
          :autosize="{ minRows: 5, maxRows: 5 }"
          v-loading="$wait.is($waiters.License.LicensesUpdate)"
        >
        </el-input>
        <button
          v-if="hasPermission('license-manage')"
          class="license-general-notes-action"
          :class="{ active: notesChanged }"
          @click="updateLicenseNotes()"
          :disabled="notesChanged"
        >
          <i class="el-icon-check" v-loading="$wait.is($waiters.License.LicensesUpdate)"></i>
        </button>
      </div>
    </div>
    <change-tags-panel
      :show="showChangeTagsPanel"
      :license="licenseDetails"
      @close="showChangeTagsPanel = false"
      @reload="onReload"
    />
  </div>
</template>

<script>
import { localeService } from '@/api/Services/License/LocaleService'
import { DateFormats, UtilityService } from '@/api/Services/License/UtilityService'
import { LicensesService } from '@/api/Services/License/LicensesService'
import ChangeTagsPanel from '@/views/License/children/subComp/licenseViewTabs/licenseEditPanels/changeTagsPanel.vue'
import { LicenseEnvironmentUpdateRequestDto } from '@/api/Services/License/dto/request/license-environment-update-request.dto'
import {
  LicenseEnvironmentEnum,
  LicenseEnvironmentEnumHelper,
} from '@/api/Services/License/enums/license-environment.enum'

export default {
  name: 'LicenseGeneralInfo',

  components: {
    ChangeTagsPanel,
  },

  data() {
    return {
      showChangeTagsPanel: false,
      toggleEnvironmentChange: false,
      environments: LicenseEnvironmentEnumHelper.getOptions(),
      tempEnv: 0,
      license: {
        countryCode: '',
        companyName: '',
        email: '',
        firstName: '',
        lastName: '',
        firstHeartbeat: '',
        expiredAt: '',
        activatedAt: '',
        createdAt: '',
        tags: '',
        activeTotalClientCapacity: -1,
        totalClientsInUse: -1,
        activeServerCapacity: -1,
        serversInUse: -1,
      },
      tempNotes: null,
    }
  },

  props: {
    licenseDetails: Object,
  },

  methods: {
    onClickChangeTags() {
      this.showChangeTagsPanel = true
    },

    onClickChangeEnv() {
      this.toggleEnvironmentChange = !this.toggleEnvironmentChange
    },

    getIndividualTags(tagstr) {
      if (tagstr) {
        let a = 0
        const tagsArry = tagstr.split(', ').map((tag) => {
          a++
          return { id: a, val: tag }
        })
        if (tagsArry.length > 0) {
          const lastElement = tagsArry[tagsArry.length - 1]
          lastElement.val = lastElement.val.slice(0, -1)
          tagsArry[tagsArry.length - 1] = lastElement
        }
        return tagsArry
      }
      return []
    },

    getFormattedDate(date) {
      return UtilityService.getFormattedDate(date, DateFormats.MINUTES)
    },

    getCountryFromCode(locale) {
      if (locale) {
        const thisLocale = localeService.getLocaleObjectFromCode(locale)
        if (thisLocale) {
          return thisLocale.title
        }
      }
      return ''
    },

    updateLicenseEnv() {
      if (this.tempEnv !== this.license.environment) {
        this.$request(async () => {
          const dto = new LicenseEnvironmentUpdateRequestDto()
          dto.environment = this.tempEnv
          const { data } = await LicensesService.updateEnvironment(dto, this.license.key)
          if (data.success) {
            this.license.environment = this.tempEnv
          }
          this.$emit('reload')
        }, this.$waiters.License.LicensesUpdate)
      }
      this.onClickChangeEnv()
    },

    updateLicenseNotes() {
      this.$request(async () => {
        const req = {
          notes: this.tempNotes,
        }
        const { data } = await LicensesService.updateNotes(req, this.license.key)
        if (data.success) {
          this.license.notes = this.tempNotes
        }
        this.$emit('reload')
      }, this.$waiters.License.LicensesUpdate)
    },

    onReload() {
      this.$emit('reload')
    },
  },

  watch: {
    licenseDetails() {
      this.license = this.licenseDetails
      this.tempNotes = this.license.notes
      this.toggleEnvironmentChange = false
      this.tempEnv = this.license.environment
    },
  },

  computed: {
    LicenseEnvironmentEnum() {
      return LicenseEnvironmentEnum
    },

    notesChanged() {
      return this.tempNotes === this.license.notes
    },
  },
}
</script>

<style lang="scss">
.license-general-info-main-grid {
  display: grid;
  width: 90%;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  gap: 0 50px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.license-general-headers {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdfe5;
  margin-bottom: 20px;
}

.license-general-info-sub-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 30% 60% 10%;
  grid-template-rows: auto;
  gap: 20px 10px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.license-general-customer-info-sub-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  gap: 20px 10px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.license-general-input-label {
  font-weight: bold;
}

.license-general-input-label::after {
  content: ':';
}

.license-general-info-notes-header {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdfe5;
  margin-bottom: 0px;
}

.license-general-info-notes {
  grid-column: 1/-1;
  display: grid;
  width: 100%;
  grid-template-columns: 510px 10px;
  grid-template-rows: auto;
  gap: 0px 5px;
  justify-items: stretch;
  align-items: end;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.license-edit-tags-action {
  width: 20px;
  height: 20px;
  padding: 0px !important;
  color: $color-dark;
  background-color: mix($color-dark, #ffffff, 5);
  border: solid 1px mix($color-dark, #ffffff, 50);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 10ms ease-out 10ms;
}
.license-edit-tags-action:hover {
  background-color: mix($color-dark, #ffffff, 0);
  border-color: mix($color-dark, #ffffff, 40);
}

.license-edit-tags-action:active {
  background-color: mix($color-dark, #ffffff, 10);
  border-color: mix($color-dark, #ffffff, 60);
}

.license-general-notes-action {
  width: 20px;
  height: 20px;
  padding: 0px !important;
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 10ms ease-out 10ms;
}

.license-general-notes-action:hover .active {
  background-color: mix(#67c23a, #ffffff, 75);
  border-color: mix(#67c23a, #ffffff, 75);
}

.license-general-notes-action:active .active {
  background-color: mix(#67c23a, #000000, 75);
  border-color: mix(#67c23a, #000000, 75);
}

.license-general-flex {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  justify-content: flex-start;
  flex-direction: row;
}

.license-general-tags-span {
  background-color: $color-blue-20;
  height: 1.4em;
  line-height: 1.2em;
  border-radius: 5px;
  margin: 0 2px;
  padding: 2px 5px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
