<template>
  <svg :width="width" viewBox="0 0 400 103" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2_3)">
      <path d="M74.6231 18.6623H61.0615V9.75757L74.6231 3.77242V18.6623Z" :fill="color" />
      <path d="M74.6231 26.115H61.0615V78.6214H74.6231V26.115Z" :fill="color" />
      <path
        d="M132.377 46.383V78.6214H118.815V48.058C118.815 40.9203 114.508 37.2401 108.408 37.2401C101.785 37.2401 96.8385 41.1278 96.8385 50.263V78.6214H83.2769V26.1149H96.8385V32.0002C99.9923 27.2751 105.462 24.6475 112.508 24.6475C123.654 24.6475 132.377 32.4151 132.377 46.383Z"
        :fill="color"
      />
      <path
        d="M180.146 52.3682C180.146 43.548 173.938 37.5628 165.531 37.5628C157.123 37.5628 151.023 43.548 151.023 52.3682C151.023 61.1884 157.231 67.1736 165.531 67.1736C173.831 67.1736 180.146 61.1884 180.146 52.3682ZM193.708 26.1149V78.6291H180.146V72.4288C176.362 77.154 170.685 80.0966 163.008 80.0966C149.023 80.0966 137.454 68.0187 137.454 52.3682C137.454 36.7177 149.023 24.6398 163.008 24.6398C170.685 24.6398 176.362 27.5824 180.146 32.3075V26.1073H193.708V26.1149Z"
        :fill="color"
      />
      <path d="M201.062 7.9674L214.631 1.9592V78.6291H201.062V7.9674Z" :fill="color" />
      <path
        d="M246.146 103H231.215L241.285 76.9465L219.731 26.115H234.554L248.223 61.4036L259.892 26.115H274.4L255.269 78.6291L246.146 103Z"
        :fill="color"
      />
      <path
        d="M316.769 65.9212L322.023 78.6291H277.492V69.5938L299.677 38.8228H284.546L278.538 26.115H317.969V35.1503L295.677 65.9212H316.769Z"
        :fill="color"
      />
      <path
        d="M361.085 47.4356C359.4 39.8754 353.723 36.8329 348.046 36.8329C340.792 36.8329 335.846 40.7206 334.269 47.4356H361.085ZM349.308 67.8113C354.569 67.8113 358.769 65.6062 361.085 62.5637L372.023 68.8639C367.077 76.0015 359.192 80.0966 349.1 80.0966C331.438 80.0966 320.292 68.0187 320.292 52.3682C320.292 36.7177 331.538 24.6398 348.054 24.6398C363.615 24.6398 374.654 36.9251 374.654 52.3682C374.654 54.3658 374.446 56.1483 374.131 57.9308H334.492C336.385 64.861 342.169 67.8036 349.315 67.8036"
        :fill="color"
      />
      <path
        d="M52.1769 48.6188C51.9077 45.9758 51.0231 43.5172 49.8769 41.1585C48.2462 37.8164 45.9385 34.9813 43.0769 32.5918C40.7692 30.6634 38.2154 29.1728 35.4 28.1356C32.7385 27.1522 30 26.5683 27.1538 26.5683C26.3231 26.5683 25.4769 26.4914 24.6538 26.5836C23.1077 26.7526 21.5462 26.9294 20.0308 27.2751C17.7462 27.7899 15.5923 28.6504 13.5615 29.7875V0L0 6.00052V56.0715L0.0384615 56.0484C0.0384615 56.0484 0.0384615 56.0715 0.0384615 56.0791H11.4615C11.1615 54.8883 10.9462 53.659 10.9462 52.3682C10.9462 44.1857 17.4308 37.5628 25.4385 37.5628C33.4462 37.5628 39.9308 44.1934 39.9308 52.3682C39.9308 60.543 33.4462 67.1736 25.4385 67.1736C23.9615 67.1736 22.5615 66.8816 21.2154 66.4591C21.2154 70.3006 21.2077 74.1422 21.2308 77.9837C21.2308 78.1605 21.4692 78.4524 21.6385 78.4831C22.4154 78.6214 23.2 78.7597 23.9923 78.7751C25.8923 78.8135 27.8231 78.9749 29.6923 78.729C32.4692 78.3679 35.1538 77.5765 37.7077 76.3242C40.2077 75.0949 42.4615 73.5659 44.4615 71.6528C47.0538 69.1712 49.0385 66.267 50.4692 62.9632C51.8538 59.767 52.5 56.4172 52.5846 52.9675C52.6231 51.5154 52.3385 50.0633 52.1923 48.6035"
        :fill="color"
      />
      <path d="M400 3.77242H386.438V56.2866H400V3.77242Z" :fill="color" />
      <path d="M400 65.0761H386.438V78.6214H400V65.0761Z" fill="#ED1A40" />
      <path d="M0 69.1328V77.2538H8.13077L27.0462 50.724L0 69.1328Z" fill="#ED1A40" />
    </g>
    <defs>
      <clipPath id="clip0_2_3">
        <rect width="400" height="103" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'VBinalyzeLogo',

  props: {
    color: {
      type: String,
      default: 'black',
    },
    width: {
      type: [Number, String],
      default: 200,
    },
  },
}
</script>
