import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import { CustomerSummaryResponseDto } from '@/api/Services/Marketplace/dto/customer-summary-response.dto'
import { CreateCustomerMarketplaceDto } from '@/api/Services/Marketplace/dto/create-customer-marketplace.dto'
import { CheckInstanceReadyStatusOutput } from '@/api/Services/Marketplace/dto/check-instance-ready-status-output.dto'

export class MarketplaceService {
  static getCustomer(token: any): Promise<AxiosResponse<ResponseDto<CustomerSummaryResponseDto>>> {
    return HTTPClient.get('/marketplace/customer', { token: token })
  }

  static getCustomerTest(token: any): Promise<AxiosResponse<ResponseDto<CustomerSummaryResponseDto>>> {
    return HTTPClient.get('/marketplace/customer/test', { token: token })
  }

  static createCustomerMarketplace(customer: CreateCustomerMarketplaceDto): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.post('/customers/one/marketplace', customer)
  }
  static registerServer(licenseKey: string, password: string): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.post('/customers/one/instance/saas?licenseKey=' + licenseKey, { password: password })
  }
  static checkInstanceStatus(license: string): Promise<AxiosResponse<ResponseDto<CheckInstanceReadyStatusOutput>>> {
    return HTTPClient.get('/customers/one/instance/check-status', { licenseKey: license })
  }
}
