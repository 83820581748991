<template>
  <el-container class="tags-page">
    <el-header class="tags-header">
      <div class="tags-count">
        <h3 v-text="`${$t('Tags')}`" />
      </div>
    </el-header>

    <VCard>
      <el-table
        :data="tableData"
        stripe
        empty-text="There are no tags"
        class="w-100"
        v-loading="$wait.is($waiters.License.Tags)"
        height="77vh"
      >
        <!-- Val-->
        <el-table-column prop="tag" label="Tag" min-width="100">
          <template v-slot="scope">
            <a v-text="scope.row.val" @click="onClickLicenseCount(scope.row.val)" />
          </template>
        </el-table-column>
        <el-table-column prop="tag" label="Created Date" min-width="100">
          <template v-slot="scope">
            {{ scope.row.createdAt }}
          </template>
        </el-table-column>
        <el-table-column prop="tag" label="License Count" min-width="100">
          <template v-slot="scope">
            <a v-text="scope.row.licenseCount" @click="onClickLicenseCount(scope.row.val)" />
          </template>
        </el-table-column>
        <el-table-column prop="tag" label="Actions" min-width="100">
          <template v-slot="scope">
            <div class="operations">
              <el-button
                type="text"
                @click="openEditTagDetail(scope.row)"
                :disabled="!hasPermission('tags-manage')"
                size="small"
                >Edit</el-button
              >
              <div>
                <el-popconfirm title="Are you sure" @confirm="onClickDelete(scope.row.id)">
                  <template #reference>
                    <el-button
                      type="text"
                      size="small"
                      :loading="$wait.is($waiters.License.TagDelete)"
                      v-text="$t('Delete')"
                      :disabled="!hasPermission('tags-manage')"
                    >
                    </el-button>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handlePaginationPageChange"
        @size-change="handlePaginationSizeChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="sizes, prev, pager, next, jumper"
        :page-sizes="[50, 100]"
        :total="totalEntityCount"
      >
      </el-pagination>
    </VCard>

    <UpdateVersionTagPanel
      :show="updateTagPanelVisible"
      :tag="selectedTag"
      @close="updateTagPanelVisible = false"
      @reload="fetchTags"
    />
  </el-container>
</template>

<script>
import { TagsService } from '@/api/Services/License/TagsService'
import UpdateVersionTagPanel from '@/views/License/children/subComp/updateVersionTagPanel.vue'
import * as $waiters from '@/constants/Waiters'

export default {
  name: 'Tags',

  computed: {
    $waiters() {
      return $waiters
    },
  },

  components: {
    UpdateVersionTagPanel,
  },

  data() {
    return {
      tableData: [],
      totalEntityCount: 0,
      pageSize: 50,
      currentPage: 0,
      updateTagPanelVisible: false,
      selectedTag: null,
    }
  },

  methods: {
    fetchTags(page = { currentPage: this.currentPage, pageSize: this.pageSize }) {
      this.$request(async () => {
        const req = this.buildPaginationRequest(page)
        const { data } = await TagsService.getPaginated(req)
        if (data.success) {
          this.tableData = data.result.entities
          this.totalEntityCount = data.result.totalEntityCount
          this.currentPage = data.result.currentPage
          this.pageSize = data.result.pageSize
        }
      }, this.$waiters.License.Tags)
    },

    handlePaginationPageChange(val) {
      this.fetchTags({ page: val, size: this.pageSize })
    },

    handlePaginationSizeChange(val) {
      this.fetchTags({ page: this.currentPage, size: val })
    },

    buildPaginationRequest(page) {
      const request = {}
      request.pageNumber = page.page
      request.pageSize = page.size
      return request
    },

    openEditTagDetail(tag) {
      this.selectedTag = tag
      this.updateTagPanelVisible = true
    },

    onClickDelete(id) {
      const onSuccess = async () => {
        await TagsService.delete(id)

        this.fetchTags({ page: this.currentPage, size: this.pageSize })
      }

      const onError = async (error) => {
        this.errorMessage = error?.response?.data?.message || this.$t('Something went wrong')
        this.$message.error(this.errorMessage)
      }

      this.$request(onSuccess, this.$waiters.License.TagDelete, onError)
    },

    onClickLicenseCount(tagVal) {
      this.$router.push({ name: 'LicenseLicenses', params: { filter: { tag: tagVal } } })
    },
  },

  created() {
    this.fetchTags({ page: this.currentPage, size: this.pageSize })
  },
}
</script>

<style lang="scss">
.tags-header {
  height: auto !important;
  padding: 0 2px !important;
  margin: 0 0 10px 0 !important;
  display: flex !important;
  align-items: baseline;
}

.tags-count {
  flex: 1;
}

.operations {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
