<template>
  <div id="app">
    <div class="license-views-background" v-if="!hasHeader()">
      <RouterView />
    </div>

    <!-- Logo -->
    <RouterLink to="/" class="binalyze-logo" v-if="hasHeader()">
      <VBinalyzeLogo />
    </RouterLink>

    <!-- Navigation -->
    <div style="align-self: center; display: flex" v-if="$route.name === 'Home'">
      <el-button
        class="p-none"
        style="width: 150px; align-self: center"
        size="large"
        type="primary"
        @click="routeToFIS"
      >
        FIS Customers
      </el-button>

      <el-button class="p-none" size="large" type="primary" @click="routeToLicense">Licenses</el-button>

      <el-button
        class="p-none"
        style="width: 150px; align-self: center"
        size="large"
        type="primary"
        @click="routeToCreateInstance"
      >
        Trial Management
      </el-button>
    </div>

    <!-- Content -->
    <PerfectScrollbar class="w-100 h-100 flex-grow-1" v-if="hasHeader()">
      <RouterView />
    </PerfectScrollbar>

    <!-- Notifications -->
    <div class="toast__container" v-if="hasHeader()">
      <div class="toast__cell">
        <notificationGroup group="success" position="top">
          <div class="notification-wrapper">
            <notification v-slot="{ notifications }">
              <div class="toast toast--green" v-for="notification in notifications" :key="notification.id">
                <div class="toast__icon">
                  <svg
                    version="1.1"
                    class="toast__svg"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"
                      />
                    </g>
                  </svg>
                </div>
                <div class="toast__content">
                  <p class="toast__type">{{ notification.title }}</p>
                  <p class="toast__message">{{ notification.text }}</p>
                </div>
              </div>
            </notification>
          </div>
        </notificationGroup>

        <notificationGroup group="error" position="top">
          <div class="notification-wrapper">
            <notification v-slot="{ notifications }">
              <div class="toast toast--red" v-for="notification in notifications" :key="notification.id">
                <div class="toast__icon">
                  <svg class="toast__svg" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"
                    />
                  </svg>
                </div>
                <div class="toast__content">
                  <p class="toast__type">{{ notification.title }}</p>
                  <p class="toast__message">{{ notification.text }}</p>
                </div>
              </div>
            </notification>
          </div>
        </notificationGroup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Home',

  methods: {
    hasHeader() {
      let retval = true
      if (this.$route.meta.dontShowHeader && this.$route.meta.dontShowHeader === true) {
        retval = false
      }
      return retval
    },

    routeToLicense() {
      this.$router.push('/license/licenses')
    },

    routeToCreateInstance() {
      this.$router.push('/internal')
    },

    routeToFIS() {
      this.$router.push('/internal/air-customers')
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  color: $color-dark;
  background-color: #f4f6f8;
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.license-views-background {
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  color: $color-dark;
  background-color: $color-light-gray;
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.binalyze-logo {
  margin: 100px auto 20px auto;
  user-select: none;
}

.notification-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 25px;
}

.toast__container {
  display: table-cell;
  vertical-align: middle;
}

.toast__cell {
  display: inline-block;
}

.toast__svg {
  fill: #fff;
}

.toast {
  text-align: left;
  padding: 21px 0;
  background-color: #fff;
  border-radius: 4px;
  max-width: 500px;
  top: 0px;
  position: relative;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
}

.toast:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.toast__icon {
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  padding: 7px;
  border-radius: 50%;
  display: inline-block;
}

.toast__type {
  color: #3e3e3e;
  font-weight: 700;
  margin-top: 0;
  font-size: 15px;
  margin-bottom: 8px;
}

.toast__message {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: #878787;
  font-weight: 500;
}

.toast__content {
  padding-left: 70px;
  padding-right: 60px;
}

.toast__close {
  position: absolute;
  right: 22px;
  top: 50%;
  width: 14px;
  cursor: pointer;
  height: 14px;
  fill: #878787;
  transform: translateY(-50%);
}

.toast--green .toast__icon,
.toast--green:before {
  background-color: #2bde3f;
}

.toast--red .toast__icon,
.toast--red:before {
  background-color: #f56565;
}
</style>
