<template>
  <el-dialog
    append-to-body
    title="Update User Privileges"
    :visible="show"
    width="300px"
    @open="onDialogOpen"
    @close="$emit('close')"
  >
    <div class="edit-privileges-panel">
      <h4>{{ localUser.email }}</h4>
      <div class="edit-privileges-panel-privileges" v-for="priv in privileges">
        <el-switch v-model="priv.value" :active-text="getPrivText(priv)"> </el-switch>
      </div>
      <div class="edit-privileges-panel-buttons">
        <el-button type="primary" @click="saveUserPrivileges()">Save</el-button>
        <el-button type="secondary" @click="closeEditUserPanel()">Cancel</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { UserService } from '@/api/Services/UserService'
import { PrivilegeEnumUtils } from '@/api/Services/enums/PrivilegeEnum'
import { UserPrivilegeManageDto } from '@/api/Services/License/dto/request/user-privilege-manage.request.dto'

export default {
  name: 'userEditPanel',

  data() {
    return {
      localUser: {},
      privileges: [],
    }
  },

  props: {
    show: Boolean,
    user: Object,
  },

  methods: {
    onDialogOpen() {
      this.localUser = this.user
      this.privileges = PrivilegeEnumUtils.getPrivilegeOptions(this.localUser.privileges)
    },

    closeEditUserPanel() {
      this.showPanel = false
      this.localUser = {}
      this.privileges = []
      this.$emit('close')
    },

    getPrivText(priv) {
      return PrivilegeEnumUtils.privilegeToString(priv.name)
    },

    saveUserPrivileges() {
      this.$request(async () => {
        const request = new UserPrivilegeManageDto()
        request.privileges = this.getPrivilegesForRequest()
        request.email = this.localUser.email
        const { data } = await UserService.savePrivileges(request)

        if (data.success) {
          this.$emit('reload')
          this.$emit('close')
        }
      })
    },

    getPrivilegesForRequest() {
      return this.privileges.filter((p) => p.value).map((p) => p.name.toString())
    },
  },
}
</script>

<style lang="scss">
.edit-privileges-panel {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.edit-privileges-panel-privileges {
  width: 200px;
}

.edit-privileges-panel-buttons {
  width: 200px;
  display: flex;
  justify-content: space-between;
}
</style>
