import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import { PagingQueryResultDto } from '@/api/Services/License/dto/paging-query-result.dto'
import { ServerSummaryResponseDto } from '@/api/Services/License/dto/response/server-summary-response.dto'
import { ServerStatusUpdateRequestDto } from '@/api/Services/License/dto/request/server-status-update-request.dto'

export class ServerService {
  static getServers(params: {
    pageSize: number
    pageNumber: number
    licenseKey: string
  }): Promise<AxiosResponse<ResponseDto<PagingQueryResultDto<ServerSummaryResponseDto[]>>>> {
    return HTTPClient.get('/oem/servers', params)
  }
  static updateStatus(payload: ServerStatusUpdateRequestDto, id: number): Promise<AxiosResponse> {
    return HTTPClient.patch(`/oem/servers/${id}/status`, payload)
  }
}
