import store from '@/store'
// eslint-disable-next-line no-unused-vars
export function checkAuth(router) {
  return async function (to, _from, next) {
    const isAuthRequired = to.matched.some((record) => record.meta.authRequired)

    if (isAuthRequired) {
      if (!store.state.isAuth) {
        await store.dispatch('checkUser')
        if (!store.state.isAuth) {
          return next({
            name: 'SignIn',
          })
        }
      }
    }

    return next()
  }
}
