import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import { PagingQueryResultDto } from '@/api/Services/License/dto/paging-query-result.dto'
import { PaginatedApiTokensRequestDto } from '@/api/Services/License/dto/request/paginated-api-tokens-request.dto'
import { ApiTokenDto } from '@/api/Services/License/dto/response/api-token.dto'
import { CreateApiTokenDto } from '@/api/Services/License/dto/request/create-api-token.dto'

export class ApiTokensService {
  static create(payload: CreateApiTokenDto): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.post(`/tokens`, payload)
  }

  static getPaginated(
    payload: PaginatedApiTokensRequestDto,
  ): Promise<AxiosResponse<ResponseDto<PagingQueryResultDto<ApiTokenDto[]>>>> {
    return HTTPClient.post(`/tokens/paginated/`, payload)
  }

  static delete(token: string): Promise<any> {
    return HTTPClient.delete(`/tokens/${token}`)
  }
}
