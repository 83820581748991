<template>
  <div class="license-devices-view">
    <el-table :data="history" style="width: 100%" v-loading="$wait.is($waiters.License.History)">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-card v-if="props.row.eventName === 'create'">
            <div class="license-device-details-grid">
              <span class="license-general-input-label">Description</span>
              <span>{{ getLongDesc(props.row.eventName, props.row.details.LicenseKey) }}</span>

              <span class="license-general-input-label">Created On</span>
              <span>{{ getFormattedDate(props.row.details.OccuredOn) }}</span>

              <span class="license-general-input-label">Is Lifetime</span>
              <span>{{ props.row.details.IsLifetime }}</span>

              <span class="license-general-input-label">Is Offline</span>
              <span>{{ props.row.details.IsOffline }}</span>

              <span class="license-general-input-label">Type</span>
              <span>{{ props.row.details.Type }}</span>
            </div>
          </el-card>

          <el-card v-if="props.row.eventName === 'add-quota'">
            <div class="license-device-details-grid">
              <span class="license-general-input-label">Description</span>
              <span>{{ getLongDesc(props.row.eventName, props.row.details.LicenseKey) }}</span>

              <span class="license-general-input-label">Start Date</span>
              <span>{{ getFormattedDate(props.row.details.ActivatedAt) }}</span>

              <span class="license-general-input-label">End Date</span>
              <span>{{ props.row.details.ExpiredDate }}</span>

              <span class="license-general-input-label">Total Asset Capacity</span>
              <span>{{ props.row.details.TotalClientCapacity }}</span>

              <span class="license-general-input-label">Server Capacity</span>
              <span>{{ props.row.details.ServerCapacity }}</span>
            </div>
          </el-card>
          <el-card v-if="props.row.eventName === 'update-quota-dates'">
            <div class="license-device-details-grid">
              <span class="license-general-input-label">Description</span>
              <span>{{ getLongDesc(props.row.eventName, props.row.details.LicenseKey) }}</span>

              <span class="license-general-input-label">Old Period</span>
              <span>{{ props.row.details.OldPeriod }}</span>

              <span class="license-general-input-label">New Period</span>
              <span>{{ props.row.details.NewPeriod }}</span>

              <span class="license-general-input-label">Days Extended</span>
              <span>{{ props.row.details.ExtendedPeriod }}</span>

              <span class="license-general-input-label">New Expiration Date</span>
              <span>{{ getFormattedDate(props.row.details.ExpirationDate) }}</span>
            </div>
          </el-card>

          <el-card v-if="props.row.eventName === 'update-quota-capacities'">
            <div class="license-device-details-grid">
              <span class="license-general-input-label">Description</span>
              <span>{{ getLongDesc(props.row.eventName, props.row.details.LicenseKey) }}</span>

              <span class="license-general-input-label">Capacity Change Type</span>
              <span>{{ props.row.details.CapacityType }}</span>

              <span class="license-general-input-label">Old Capacity</span>
              <span>{{ props.row.details.OldCapacity }}</span>

              <span class="license-general-input-label">New Capacity</span>
              <span>{{ props.row.details.NewCapacity }}</span>
            </div>
          </el-card>
        </template>
      </el-table-column>
      <el-table-column label="Event">
        <template slot-scope="props">
          {{ getSmallDesc(props.row.eventName) }}
        </template></el-table-column
      >
      <el-table-column label="Event Date">
        <template slot-scope="props">
          {{ getFormattedDate(props.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column label="Performed By" prop="createdBy"></el-table-column>
    </el-table>
    <el-pagination
      @current-change="handlePaginationPageChange"
      @size-change="handlePaginationSizeChange"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      layout="sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :total="totalEntityCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import { LicenseHistoryService } from '@/api/Services/License/LicenseHistoryService'
import * as $waiters from '@/constants/Waiters'
import { DateFormats, UtilityService } from '@/api/Services/License/UtilityService'
import { LicenseHistoryEventEnum } from '@/api/Services/License/enums/license-history-event.enum'

export default {
  name: 'LicenseHistoryInfo',

  computed: {
    $waiters() {
      return $waiters
    },
  },

  data: function () {
    return {
      pageNum: 1,
      pageSize: 10,
      history: [],
      totalEntityCount: 0,
    }
  },

  methods: {
    getHistoryDetails(paginationData) {
      this.$request(async () => {
        const req = {}
        req.pageSize = paginationData.size
        req.pageNumber = paginationData.page
        req.licenseKey = this.licenseDetails.key
        const { data } = await LicenseHistoryService.getHistory(req)
        if (data.success) {
          this.history = data.result.entities
          this.pageNum = data.result.currentPage
          this.pageSize = data.result.pageSize
          this.totalEntityCount = data.result.totalEntityCount
        }
      }, this.$waiters.License.History)
    },

    getFormattedDate(date) {
      return UtilityService.getFormattedDate(date, DateFormats.MINUTES)
    },

    getSmallDesc(licenseHistory) {
      switch (licenseHistory) {
        case LicenseHistoryEventEnum.Create: {
          return 'License Created'
        }
        case LicenseHistoryEventEnum.UpdateTags: {
          return 'License Tags Updated'
        }
        case LicenseHistoryEventEnum.AddQuota: {
          return 'Quota Added'
        }
        case LicenseHistoryEventEnum.UpdateQuotaStatus: {
          return 'Quota Status Changed'
        }
        case LicenseHistoryEventEnum.UpdateQuotaDates: {
          return 'Quota Dates Changed'
        }
        case LicenseHistoryEventEnum.UpdateQuotaCapacities: {
          return 'Quota Capacity Changed'
        }
        case LicenseHistoryEventEnum.UpdateServerStatus: {
          return 'Server Status Updated'
        }
      }
    },

    getLongDesc(licenseHistory, key) {
      switch (licenseHistory) {
        case LicenseHistoryEventEnum.Create: {
          return 'License with key ' + key + ' has been activated'
        }
        case LicenseHistoryEventEnum.UpdateTags: {
          return 'License with key ' + key + ' has been activated'
        }
        case LicenseHistoryEventEnum.AddQuota: {
          return 'Quota added to license with key ' + key
        }
        case LicenseHistoryEventEnum.UpdateQuotaStatus: {
          return 'Quota Status Changed'
        }
        case LicenseHistoryEventEnum.UpdateQuotaDates: {
          return 'Quota Dates Changed'
        }
        case LicenseHistoryEventEnum.UpdateQuotaCapacities: {
          return 'Updated quota capacity for license with key ' + key
        }
        case LicenseHistoryEventEnum.UpdateServerStatus: {
          return 'Server Status Updated'
        }
      }
    },

    handlePaginationPageChange(val) {
      this.getHistoryDetails({ page: val, size: this.pageSize })
    },

    handlePaginationSizeChange(val) {
      this.getHistoryDetails({ page: this.currentPage, size: val })
    },
  },

  props: {
    licenseDetails: Object,
  },

  watch: {
    licenseDetails() {
      this.getHistoryDetails({ page: this.currentPage, size: this.pageSize })
    },
  },
}
</script>
