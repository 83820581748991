<template>
  <div class="v-circle-loader flex-center">
    <div class="v-circle-loader-progress" :style="style" />
  </div>
</template>

<script>
export default {
  name: 'VCircleLoader',

  props: {
    color: {
      type: String,
      default: '#438af1', // #25af54
    },
    size: {
      type: String,
      default: '100px',
    },
    border: {
      type: String,
      default: '10px',
    },
  },

  computed: {
    style() {
      return {
        color: this.color,
        width: this.size,
        height: this.size,
        minWidth: this.size,
        minHeight: this.size,
        maxWidth: this.size,
        maxHeight: this.size,
        borderWidth: this.border,
      }
    },
  },
}
</script>

<style lang="scss">
.v-circle-loader {
  position: relative;

  &-progress {
    z-index: 9;
    border-radius: 50%;
    border: 10px solid $color-dark-10;
    border-top: 10px solid currentColor;
    @include animation(spin 1s linear infinite);
  }

  span {
    position: absolute;
    font-size: 10px;
  }
}
</style>
