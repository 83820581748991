import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import { PagingUserRequestDto } from '@/api/Services/License/dto/request/paging-user.request.dto'
import { UserPrivilegeManageDto } from '@/api/Services/License/dto/request/user-privilege-manage.request.dto'
import { PagingQueryResultDto } from '@/api/Services/License/dto/paging-query-result.dto'

export class UserService {
  static getOwnPrivileges(): Promise<AxiosResponse<ResponseDto<string[]>>> {
    return HTTPClient.get('/user/current')
  }
  static getUsers(payload: PagingUserRequestDto): Promise<AxiosResponse<ResponseDto<PagingQueryResultDto<any>>>> {
    return HTTPClient.post('user/paginated', payload)
  }
  static savePrivileges(payload: UserPrivilegeManageDto): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.post('user/privileges', payload)
  }
}
