<template>
  <el-dialog append-to-body title="Download Offline License File" :visible="show" width="380px" @close="$emit('close')">
    <h4>To download the offline license file, please enter the seed key below:</h4>
    <el-input v-model="seedKey" />

    <div class="download-license-buttons">
      <el-button type="info" :disabled="!canDownload" @click="downloadLicense()">Download</el-button>
      <el-button type="secondary" @click="closeLicenseDownloadPanel()">Cancel</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'licenseDownloadPanel',

  data() {
    return {
      seedKey: '',
    }
  },

  props: {
    show: Boolean,
    licenseKey: String,
  },

  methods: {
    closeLicenseDownloadPanel() {
      this.$emit('close')
    },

    downloadLicense() {
      window.open(`/api/oem/licenses/${this.licenseKey}/download/${this.seedKey}`, '_blank')
      this.seedKey = ''

      this.$emit('close')
    },
  },

  computed: {
    canDownload: function () {
      return this.seedKey.length > 0
    },
  },
}
</script>

<style lang="scss">
.download-license-buttons {
  display: flex;
  padding-top: 25px;
  flex-flow: row;
  justify-content: space-between;
}
</style>
