export enum PeriodEnum {
  Per2Week,
  PerMonth,
  Per2Month,
  Per3Month,
  Per6Month,
  PerYear,
  Per2Year,
  Per3Year,
  Custom,
}

export class PeriodEnumUtils {
  static getPeriodOptionsForDropdown() {
    return [
      {
        value: PeriodEnum.Per2Week,
        label: '2 Weeks',
      },

      {
        value: PeriodEnum.PerMonth,
        label: '1 Month',
      },
      {
        value: PeriodEnum.Per2Month,
        label: '2 Months',
      },
      {
        value: PeriodEnum.Per3Month,
        label: '3 Months',
      },
      {
        value: PeriodEnum.Per6Month,
        label: '6 Months',
      },
      {
        value: PeriodEnum.PerYear,
        label: '1 Year',
      },
      {
        value: PeriodEnum.Per2Year,
        label: '2 Years',
      },
      {
        value: PeriodEnum.Per3Year,
        label: '3 Years',
      },
      {
        value: PeriodEnum.Custom,
        label: 'Custom period as days',
      },
    ]
  }
  static getDefaultPeriod() {
    return PeriodEnum.PerYear
  }
}
