<template>
  <div class="v-card" :class="{ 'pb-0': $slots.footer }">
    <!-- Header -->
    <header class="v-card__header" v-if="title || $slots.header">
      <slot name="header">
        <h3 class="mb-0 c-dark-90" v-text="title" />
      </slot>
    </header>
    <!-- Content -->
    <div class="v-card__content">
      <slot />
    </div>
    <!-- Footer -->
    <footer class="v-card__footer" v-if="$slots.footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'VCard',

  props: {
    title: String,
  },
}
</script>

<style lang="scss">
.v-card {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1px;
  padding: 20px;
  background: #fff;
  @include boxShadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.04) !important);
  border-radius: $radius;

  &__content {
    flex: 1;
    height: calc(100% - 74px);
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding: 0 0 $spacer-3 0;

    .row {
      flex: 1;
      width: unset !important;
    }

    & > h4 {
      margin-bottom: $spacer;
    }

    & > label {
      margin-bottom: $spacer;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
    }
  }

  &__footer {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    font-size: $font-size-small;
    padding: 20px;
    margin: 0px -20px 0px -20px;
    border-radius: 0 0 3px 3px;
    background-color: #f7fafb;
    @include boxShadow(0 1px rgba(15, 33, 46, 0.1) inset);
  }
}
</style>
