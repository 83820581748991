import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import { CustomerSummaryResponseDto } from '@/api/Services/License/dto/response/customer-summary-response.dto'
import { HubspotCompanyResponseDto } from '@/api/Services/License/dto/response/hubspot-company-response.dto'

export class customersService {
  static searchCustomers(payload: string): Promise<AxiosResponse<ResponseDto<string[]>>> {
    return HTTPClient.get('/oem/customers', { companyName: payload })
  }

  static getCustomerByCompanyName(payload: string): Promise<AxiosResponse<ResponseDto<CustomerSummaryResponseDto>>> {
    return HTTPClient.get('/oem/customers/' + payload)
  }

  static searchHubspotCompanies(payload: string): Promise<AxiosResponse<ResponseDto<HubspotCompanyResponseDto[]>>> {
    return HTTPClient.get('/oem/hubspot-customers/companies', { name: payload })
  }

  static searchHubspotContacts(
    companyId: number,
    payload: string,
  ): Promise<AxiosResponse<ResponseDto<HubspotCompanyResponseDto[]>>> {
    return HTTPClient.get('/oem/hubspot-customers/companies/' + companyId + '/contacts', { email: payload })
  }
}
