import 'reflect-metadata'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import * as Waiters from '@/constants/Waiters'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import dayjs from 'dayjs'
import 'element-ui/lib/theme-chalk/index.css'
import './icons'
import globalMixin from './globalMixins'
import VueClipboard from 'vue-clipboard2'

import mixins from 'vue-typed-mixins'
dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))
dayjs.extend(require('dayjs/plugin/duration'))
dayjs.extend(require('dayjs/plugin/relativeTime'))
dayjs.extend(require('dayjs/plugin/isBetween'))

Vue.use(ElementUI, { locale })

Vue.prototype.$waiters = Waiters
Vue.prototype.$dayjs = dayjs

import '@/style/app.scss'

// Auto register all components
const requireComponent = require.context('./components', true, /\.(vue)$/)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  if (componentConfig.default.name) {
    Vue.component(componentConfig.default.name, componentConfig.default || componentConfig)
  }
})

import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
Vue.use(JsonViewer)

import VueTooltip from 'v-tooltip'
Vue.use(VueTooltip, { defaultDelay: 0 })

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

import VeeValidate, { Validator } from 'vee-validate'

Vue.use(VeeValidate, { events: 'change|blur', fieldsBagName: 'field' })
Validator.extend('strong_password', {
  getMessage: () => {
    return 'It must contain at least 8 characters, 1 number, 1 uppercase and 1 lowercase letter.'
  },
  validate: (value) => {
    // (?=.*[a-z])	string must contain at least 1 lowercase alphabetical character.
    // (?=.*[A-Z])	string must contain at least 1 uppercase alphabetical character.
    // (?=.*[0-9])	string must contain at least 1 numeric character.
    // (?=.{8,})	  string must be 8 characters or longer.
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value)
  },
})

Validator.extend('alphanumeric', {
  getMessage: () => {
    return 'You must use spaces, hyphens, dots, numbers or english letters.'
  },
  validate: (value) => {
    return value.match(/^[a-z\d\-.\s]+$/i) !== null
  },
})

import Notifications from 'vt-notifications'
Vue.use(Notifications)

// Vue Wait
import VueWait from 'vue-wait'
Vue.use(VueWait)
// @ts-ignore
window.wait = new VueWait({
  registerComponent: false,
  registerDirective: false,
})

Vue.config.productionTip = false
Vue.mixin(mixins(globalMixin))

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

// @ts-ignore
window.vm = new Vue({
  router,
  store,
  i18n,
  // @ts-ignore
  wait: window.wait,
  render: (h) => h(App),
}).$mount('#app')
