import Vue from 'vue'

export default Vue.extend({
  methods: {
    async $request(callback: Function, waitKey: any, errorCallback = null) {
      if (waitKey) {
        this.$wait.start(waitKey)
      }

      try {
        await callback()
      } catch (error: any) {
        console.error(error)
        // No connection
        if (!error.response) {
          this.$wait.end(waitKey)
          return
        }

        // Error handler
        if (typeof errorCallback === 'function') {
          // @ts-ignore
          errorCallback(error)
        }
      } finally {
        this.$wait.end(waitKey)
      }
    },

    hasPermission(permissionName: string) {
      return this.$store.getters.permissionCheck(permissionName)
    },
  },
})
