<template>
  <el-container class="settings-page">
    <el-header class="settings-header">
      <div class="settings-title">
        <h3 v-text="$t('Settings')" />
      </div>
    </el-header>

    <VCard>
      <el-form
        label-position="top"
        v-loading="$wait.is($waiters.License.Settings)"
        class="settings-form"
      >
        <el-form-item :label="$t('Renewal Period (days)')">
          <el-input-number
            v-model="renewalPeriod"
            :min="1"
            controls-position="right"
            @change="updateRenewalPeriod"
          />
        </el-form-item>

        <el-form-item :label="$t('Reminder Days')">
          <div class="reminder-days-input">
            <el-input-number
              v-model="newReminderDay"
              :min="1"
              controls-position="right"
              :placeholder="$t('Enter days')"
            />
            <el-button
              type="primary"
              @click="addReminderDay"
              :disabled="!newReminderDay"
            >
              {{ $t('Add') }}
            </el-button>
          </div>
          <div class="reminder-days-tags">
            <el-tag
              v-for="day in reminderDays"
              :key="day"
              closable
              @close="removeReminderDay(day)"
              class="mr-2 mb-2"
            >
              {{ $t('{day} days before', { day }) }}
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
    </VCard>
  </el-container>
</template>

<script>
import { SettingsService } from '@/api/Services/License/SettingsService'

export default {
  name: 'Settings',

  data() {
    return {
      renewalPeriod: 30,
      reminderDays: [],
      newReminderDay: null,
    }
  },

  methods: {
    fetchSettings() {
      this.$request(async () => {
        const { data } = await SettingsService.get()
        if (data.success) {
          this.renewalPeriod = data.result.renewalPeriod
          this.reminderDays = data.result.reminderDays
        }
      }, this.$waiters.License.Settings)
    },

    async updateRenewalPeriod(value) {
      this.$request(async () => {
        const { data } = await SettingsService.updateRenewalPeriod({ renewalPeriod: value })
        if (data.success) {
          this.$message.success(this.$t('Renewal period updated successfully'))
        }
      }, this.$waiters.License.Settings)
    },

    async updateReminderDays() {
      this.$request(async () => {
        const { data } = await SettingsService.updateReminderDays({ reminderDays: this.reminderDays })
        if (data.success) {
          this.$message.success(this.$t('Reminder days updated successfully'))
        }
      }, this.$waiters.License.Settings)
    },

    addReminderDay() {
      if (!this.reminderDays.includes(this.newReminderDay)) {
        this.reminderDays.push(this.newReminderDay)
        this.reminderDays.sort((a, b) => a - b)
        this.updateReminderDays()
        this.newReminderDay = null
      }
    },

    removeReminderDay(day) {
      this.reminderDays = this.reminderDays.filter(d => d !== day)
      this.updateReminderDays()
    },
  },

  created() {
    this.fetchSettings()
  },
}
</script>

<style lang="scss" scoped>
.settings-header {
  height: auto !important;
  padding: 0 2px !important;
  margin: 0 0 10px 0 !important;
  display: flex !important;
  align-items: baseline;
}

.settings-title {
  flex: 1;
}

.settings-form {
  padding: 20px;
  max-width: 500px;
}

.reminder-days-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.reminder-days-tags {
  display: flex;
  flex-wrap: wrap;
}
</style>