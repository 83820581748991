export enum LicenseHistoryEventEnum {
  Create = 'create',
  Delete = 'delete',
  UpdateTags = 'update-tags',
  AddQuota = 'add-quota',
  UpdateQuotaStatus = 'update-quota-status',
  UpdateQuotaCapacities = 'update-quota-capacities',
  UpdateQuotaDates = 'update-quota-dates',
  UpdateServerStatus = 'update-server-status',
}
