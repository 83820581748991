import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { HTTPClient } from '@/api/HTTPClient'
import {
  LicenseQuotasEnterpriseEntityDto,
  LicenseQuotasIREntityDto,
} from '@/api/Services/License/dto/response/license-quotas-entity-dto'
import { QuotaDateUpdateRequestDto } from '@/api/Services/License/dto/request/quota-date-update-request.dto'
import { EnterpriseQuotaCapacityUpdateRequestDto } from '@/api/Services/License/dto/request/enterprise-quota-capacity-update-request.dto'
import { QuotaStatusUpdateRequestDto } from '@/api/Services/License/dto/request/quota-status-update-request.dto'

export class LicenseQuotasService {
  static get(
    licenseKey: number,
    licenceType: number,
  ): Promise<AxiosResponse<ResponseDto<LicenseQuotasEnterpriseEntityDto | LicenseQuotasIREntityDto[]>>> {
    return HTTPClient.get('/oem/quotas', { licenseKey: licenseKey, licenseType: licenceType })
  }

  static updateDate(payload: QuotaDateUpdateRequestDto, id: number): Promise<AxiosResponse> {
    return HTTPClient.patch(`/oem/quotas/${id}/date`, payload)
  }

  static updateCapacity(payload: EnterpriseQuotaCapacityUpdateRequestDto, id: number): Promise<AxiosResponse> {
    return HTTPClient.patch(`/oem/quotas/enterprise/${id}/capacity`, payload)
  }

  static updateStatus(payload: QuotaStatusUpdateRequestDto, id: number): Promise<AxiosResponse> {
    return HTTPClient.patch(`/oem/quotas/${id}/status`, payload)
  }
}
