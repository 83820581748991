import { HTTPClient } from '@/api/HTTPClient'
import { LicenseFilteredRequestDto } from '@/api/Services/License/dto/request/license-filtered-request.dto'
import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { PagingQueryResultDto } from '@/api/Services/License/dto/paging-query-result.dto'
import { LicenseSummaryViewDto } from '@/api/Services/License/dto/response/license-summary-view-dto'
import { LicenseCreateRequestDto } from '@/api/Services/License/dto/request/license-create-request.dto'
import { LicenseNotesUpdateRequestDto } from '@/api/Services/License/dto/request/license-notes-update-request.dto'
import { LicenseTagsUpdateRequestDto } from '@/api/Services/License/dto/request/license-tags-update-request.dto'
import { LicenseEnvironmentUpdateRequestDto } from '@/api/Services/License/dto/request/license-environment-update-request.dto'

export class LicensesService {
  static createLicense(payload: LicenseCreateRequestDto): Promise<AxiosResponse<ResponseDto<string>>> {
    return HTTPClient.post('/oem/licenses', payload)
  }
  static createLicenseWithHubspotCustomer(
    payload: LicenseCreateRequestDto,
  ): Promise<AxiosResponse<ResponseDto<string>>> {
    return HTTPClient.post('/oem/licenses/hubspot', payload)
  }
  static getLicensesSummary(
    payload: LicenseFilteredRequestDto,
  ): Promise<AxiosResponse<ResponseDto<PagingQueryResultDto<LicenseSummaryViewDto[]>>>> {
    return HTTPClient.post('/oem/licenses/filtered', payload)
  }
  static updateNotes(
    payload: LicenseNotesUpdateRequestDto,
    licenseKey: string,
  ): Promise<AxiosResponse<ResponseDto<void>>> {
    return HTTPClient.patch('/oem/licenses/' + licenseKey + '/notes', payload)
  }

  static updateEnvironment(
    payload: LicenseEnvironmentUpdateRequestDto,
    licenseKey: string,
  ): Promise<AxiosResponse<ResponseDto<void>>> {
    return HTTPClient.patch('/oem/licenses/' + licenseKey + '/environment', payload)
  }

  static updateTags(
    payload: LicenseTagsUpdateRequestDto,
    licenseKey: string,
  ): Promise<AxiosResponse<ResponseDto<void>>> {
    return HTTPClient.patch('/oem/licenses/' + licenseKey + '/tags', payload)
  }
  static deleteLicense(key: string): Promise<AxiosResponse<ResponseDto<void>>> {
    return HTTPClient.delete('/oem/licenses/' + key)
  }
  static fetchLastUpdateTime(): Promise<AxiosResponse<ResponseDto<Date>>> {
    return HTTPClient.get('/oem/refresh-info/summary-view')
  }
  static reloadMaterializedView(): Promise<AxiosResponse<ResponseDto<void>>> {
    return HTTPClient.post('/oem/refresh-info/refresh')
  }

  static getReportCSV(filter: any, getActive: boolean): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.get(`oem/licenses/report/csv`, { filters: filter, getActive: getActive })
  }
}
