<template>
  <div class="form-text-wrapper" :class="`--${size}`" :data-name="name">
    <label v-if="showLabel" :for="`text-box-${_uid}`" class="mr-auto">
      {{ label || name }}
      <strong v-if="isShowRequiredMark" class="input-required-mark">*</strong>
    </label>

    <div class="form-text-input">
      <FaIcon v-if="iconLeft" class="form-icon --left c-dark-70" :icon="iconLeft" />

      <input
        :id="`text-box-${_uid}`"
        ref="el"
        :name="name"
        :value="value"
        :class="clazz"
        class="form-text"
        data-lpignore="true"
        autocorrect="off"
        autocomplete="off"
        spellcheck="false"
        :style="style"
        v-bind="$attrs"
        v-on="inputListeners"
        :type="type || 'text'"
        :placeholder="$attrs.placeholder || label || name"
      />

      <!-- Icon -->
      <FaIcon v-if="icon" class="form-icon c-dark-70" :icon="icon" />
      <slot v-else name="icon" />

      <!-- Clearable Icon -->
      <div v-if="value && clearable">
        <FaIcon icon="times-circle" class="form-icon cr-pointer c-dark-40" @click="$emit('input', '')" />
      </div>

      <!-- Password Icon -->
      <div v-if="$attrs.type == 'password'">
        <button
          type="button"
          @click="onClickTogglePassword"
          class="pass-form-icon cr-pointer"
          v-tooltip="$t(isPassword ? 'Show' : 'Hide')"
        >
          <FaIcon class="c-dark-70" :icon="passwordIcon" />
        </button>
      </div>
    </div>

    <!-- Error -->
    <p class="form-text-error" v-if="getError" v-text="getError" />
  </div>
</template>

<script>
import { InputMixin } from '@/mixins/input'

export default {
  name: 'VTextBox',

  mixins: [InputMixin],

  props: {
    name: String,
    value: [String, Number],
    label: String,
    icon: String,
    iconLeft: String,
    errorMsg: String,
    nolabel: Boolean,
    clearable: Boolean,
    hideRequiredMark: Boolean,
    required: Boolean,
    theme: {
      type: String,
      default: 'default',
      validator: (v) => ['bottom-border', 'no-border', 'default'].includes(v),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (v) => ['small', 'medium'].includes(v),
    },
  },

  data() {
    return {
      type: this.$attrs.type,
    }
  },

  methods: {
    focus() {
      this.$refs.el.focus()
    },

    onClickTogglePassword() {
      this.type = this.type == 'password' ? 'text' : 'password'
    },
  },

  computed: {
    isShowRequiredMark() {
      return !this.hideRequiredMark && (this.required || this.isRequired)
    },

    showLabel() {
      return !this.nolabel && Boolean(this.label || this.name)
    },

    clazz() {
      return [`--${this.size}`, `--${this.theme}`, { '--error': this.getError }]
    },

    passwordIcon() {
      return this.type == 'password' ? 'eye' : 'eye-slash'
    },

    isPassword() {
      return this.type == 'password'
    },

    style() {
      let stylePadding = ''

      if (this.iconLeft) {
        stylePadding += 'padding-left: 28px;'
      } else if (this.icon) {
        stylePadding += 'padding-right: 28px;'
      }
      if (this.value && this.clearable) {
        stylePadding += 'padding-right: 28px;'
      }

      return stylePadding
    },

    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: (event) => this.$emit('input', event.target.value),
      })
    },
  },
}
</script>

<style lang="scss">
.form-text-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include transition(0.3s all ease);

  label {
    margin-bottom: 6px;
    font-weight: $font-weight-medium;
    color: $color-dark-90;
  }

  .form-text-error {
    color: $color-red;
    font-size: $font-size-mini;
    font-weight: $font-weight-medium;
    margin-top: $spacer-1;
  }

  .form-text-input {
    position: relative;
  }

  .form-text {
    width: 100%;
    border: 0;
    color: $color-dark;
    font-weight: $font-weight-medium;

    &::placeholder {
      font-weight: 500;
      color: $color-dark-50;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.03) !important;
      color: $color-dark-70 !important;
      cursor: no-drop;
    }

    // themes
    &.--default {
      background-color: #fff;
      border: 1px solid $color-dark-20;

      &.--error {
        border: 1px solid $color-red;
      }

      &:focus {
        border: 1px solid $color-primary;
      }
    }

    &.--no-border {
      background-color: transparent;
      border: 1px solid transparent;

      &.--error {
        border-bottom: 1px solid $color-red;
      }

      &:focus {
        border-bottom: 1px solid $color-primary;
      }
    }

    &.--bottom-border {
      background-color: transparent;
      border: 1px solid transparent;
      border-bottom: 1px solid $color-dark-10;

      &.--error {
        border-bottom: 1px solid $color-red;
      }

      &:focus {
        border-bottom: 1px solid $color-primary;
      }
    }

    // sizes
    &.--small {
      height: 32px;
      border-radius: $radius;
      padding: 0 $spacer-2 0 $spacer-2;
      font-size: $font-size-normal;
    }

    &.--medium {
      height: 42px;
      padding: 0 $spacer-2 0 $spacer-2;
      border-radius: $radius;
      font-size: $font-size-medium;
      line-height: 18px;

      &::placeholder {
        font-size: $font-size-normal;
      }
    }
  }

  // form icon
  .pass-form-icon,
  .form-icon {
    position: absolute;
    right: $spacer-2;

    &.--left {
      left: $spacer-2;
    }
  }

  &.--small {
    .form-icon {
      font-size: $font-size-normal;
      top: 10px;
    }

    .pass-form-icon {
      font-size: $font-size-normal;
      top: 7px;
    }
  }

  &.--medium {
    .form-icon {
      font-size: $font-size-medium;
      top: 15px;
    }

    .pass-form-icon {
      font-size: $font-size-normal;
      top: 12px;
    }
  }
}
</style>
