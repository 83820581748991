export enum LicenseEnvironmentEnum {
  Internal = 1,
  Demo = 2,
  Paid = 3,
}

export class LicenseEnvironmentEnumHelper {
  static translate(data: LicenseEnvironmentEnum) {
    switch (data) {
      case LicenseEnvironmentEnum.Internal:
        return 'Internal'
      case LicenseEnvironmentEnum.Demo:
        return 'Demo'
      case LicenseEnvironmentEnum.Paid:
        return 'Paid'
    }
  }
}
