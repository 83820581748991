<template>
  <span class="v-datetime" v-text="text" v-tooltip="getTooltip" @mouseover="fromNow()" />
</template>

<script>
export default {
  name: 'VDateTime',

  props: {
    value: [Date, String],
    reverse: Boolean,
    zone: String,
    dateFormat: {
      type: String,
      default: 'YYYY.MM.DD HH:mm:ss',
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    text: '',
  }),

  created() {
    this.fromNow()
  },

  computed: {
    dateTime() {
      if (this.value && this.zone) {
        return this.$dayjs.tz(this.value, this.zone)
      }
      return this.$dayjs(this.value)
    },

    getHumanReadable() {
      return this.value ? this.dateTime.fromNow() : '-'
    },

    getTooltip() {
      if (this.value && this.tooltip) {
        if (this.reverse) {
          return this.getHumanReadable
        }
        return this.dateTime.format(this.dateFormat) || '-'
      }
      return ''
    },
  },

  methods: {
    fromNow() {
      if (this.reverse) {
        this.text = this.dateTime.format(this.dateFormat) || '-'
      } else {
        this.text = this.getHumanReadable
      }
    },
  },

  watch: {
    value: 'fromNow',
  },
}
</script>
