<template>
  <div class="form-text-area-wrapper" :data-name="name">
    <label v-if="!nolabel ? label || name : false" :for="`text-area-${_uid}`">
      {{ label || name }} <b v-if="isRequired || required" class="input-required-mark">*</b>
    </label>

    <textarea
      :id="`text-area-${_uid}`"
      :value="value"
      :class="clazz"
      :name="name"
      :placeholder="$attrs.placeholder || label || name"
      v-on="inputListeners"
      v-bind="$attrs"
      class="form-text-area"
      autocorrect="off"
      spellcheck="false"
    />
    <!-- Error -->
    <p class="form-textarea-error" v-if="getError" v-text="getError" />
  </div>
</template>

<script>
import { InputMixin } from '@/mixins/input'

export default {
  name: 'VTextArea',

  mixins: [InputMixin],

  props: {
    name: String,
    value: String,
    label: String,
    nolabel: Boolean,
    required: Boolean,
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'small',
    },
  },

  computed: {
    clazz() {
      return [`--${this.size}`, `--${this.theme}`, { '--error': this.getError }]
    },

    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: (event) => this.$emit('input', event.target.value),
      })
    },
  },
}
</script>

<style lang="scss">
.form-text-area-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include transition(0.3s all ease);

  .form-textarea-error {
    font-size: 10px;
    color: $color-red;
    margin-top: $spacer-1;
  }

  .form-text-area {
    width: 100%;
    border: 0;
    background-color: #fff;

    &:disabled {
      background-color: $color-dark-5 !important;
      cursor: no-drop;
    }

    &::placeholder {
      font-weight: normal;
      font-size: $font-size-small;
      color: $color-dark-50;
    }

    // themes
    &.--default {
      color: $color-dark;
      border: 1px solid $color-dark-20;
      caret-color: $color-primary;

      &.--error {
        border: 1px solid #ff0000;
      }

      &:focus {
        border: 1px solid $color-primary;
      }
    }

    &.--no-border {
      caret-color: $color-primary;

      & + .error {
        margin: 0 0 $spacer-1 $spacer-3;
      }
    }

    // sizes
    &.--small {
      border-radius: $radius;
      padding: $spacer-2;
    }

    &.--medium {
      border-radius: $radius;
      padding: $spacer-2;
      font-size: $font-size-medium;
      line-height: 18px;
    }
  }
}
</style>
