<template>
  <el-dialog
    append-to-body
    width="20%"
    title="Change Capacity"
    @opened="onDialogOpen"
    @close="$emit('close')"
    :visible="show"
  >
    <el-form :model="form" ref="formRef" label-position="top">
      <el-form-item label="Server Capacity" prop="totalClientCapacity" :rules="[{ required: true }]">
        <el-input-number v-model="form.serverCapacity" controls-position="right" :min="1" :max="1000" />
      </el-form-item>
      <el-form-item label="Total Asset Capacity" prop="totalClientCapacity" :rules="[{ required: true }]">
        <el-input-number v-model="form.totalClientCapacity" controls-position="right" :min="1" :max="1000000000" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">Cancel</el-button>
      <el-button type="primary" @click="onClickSave" :loading="$wait.is($waiters.License.QuotaCapacityUpdate)"
        >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { LicenseQuotasService } from '@/api/Services/License/LicenseQuotasService'

export default {
  name: 'changeQuotaCapacityPanel',

  props: {
    quota: {
      required: true,
    },
    license: {
      required: true,
    },
    show: Boolean,
  },

  data() {
    return {
      dialogVisible: false,
      form: {},
    }
  },

  methods: {
    onClickSave() {
      const onSuccess = async () => {
        await LicenseQuotasService.updateCapacity(this.form, this.quota.id)
        this.$emit('reload')
        this.$emit('close')
      }

      this.$request(onSuccess, this.$waiters.License.QuotaCapacityUpdate)
    },

    onDialogOpen() {
      this.form = {
        serverCapacity: this.quota.serverCapacity,
        totalClientCapacity: this.quota.totalClientCapacity,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.el-date-editor.el-input {
  width: 100%;
}
</style>
