export enum PrivilegeEnum {
  LICENSE_CREATE = 'license-create',
  LICENSE_MANAGE = 'license-manage',
  LICENSE_DELETE = 'license-delete',
  TAGS_CREATE = 'tags-create',
  TAGS_MANAGE = 'tags-manage',
  USERS_MANAGE = 'users-manage',
  VERSIONS_MANAGE = 'versions-manage',
  TOKENS_MANAGE = 'tokens-manage',
}
export class PrivilegeEnumUtils {
  static privilegeToString(enumStr: string) {
    switch (enumStr) {
      case PrivilegeEnum.LICENSE_CREATE.toString():
        return 'Creates License'
      case PrivilegeEnum.LICENSE_MANAGE.toString():
        return 'Edits Licenses'
      case PrivilegeEnum.LICENSE_DELETE.toString():
        return 'Deletes Licenses'
      case PrivilegeEnum.TAGS_CREATE.toString():
        return 'Creates Tags'
      case PrivilegeEnum.TAGS_MANAGE.toString():
        return 'Deletes/Edits Tags'
      case PrivilegeEnum.USERS_MANAGE.toString():
        return 'Manages Users'
      case PrivilegeEnum.VERSIONS_MANAGE.toString():
        return 'Manages Versions'
      case PrivilegeEnum.TOKENS_MANAGE.toString():
        return 'Manages API Tokens'
    }
  }
  static getPrivilegeOptions(userPrivileges: string[]) {
    return [
      {
        name: PrivilegeEnum.LICENSE_CREATE,
        value: userPrivileges.includes(PrivilegeEnum.LICENSE_CREATE.toString()),
      },
      {
        name: PrivilegeEnum.LICENSE_MANAGE,
        value: userPrivileges.includes(PrivilegeEnum.LICENSE_MANAGE.toString()),
      },
      {
        name: PrivilegeEnum.LICENSE_DELETE,
        value: userPrivileges.includes(PrivilegeEnum.LICENSE_DELETE.toString()),
      },
      {
        name: PrivilegeEnum.TAGS_CREATE,
        value: userPrivileges.includes(PrivilegeEnum.TAGS_CREATE.toString()),
      },
      {
        name: PrivilegeEnum.TAGS_MANAGE,
        value: userPrivileges.includes(PrivilegeEnum.TAGS_MANAGE.toString()),
      },
      {
        name: PrivilegeEnum.USERS_MANAGE,
        value: userPrivileges.includes(PrivilegeEnum.USERS_MANAGE.toString()),
      },
      {
        name: PrivilegeEnum.VERSIONS_MANAGE,
        value: userPrivileges.includes(PrivilegeEnum.VERSIONS_MANAGE.toString()),
      },
      {
        name: PrivilegeEnum.TOKENS_MANAGE,
        value: userPrivileges.includes(PrivilegeEnum.TOKENS_MANAGE.toString()),
      },
    ]
  }
}
