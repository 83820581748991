<template>
  <label class="form-radio" :class="clazz" :for="_uid" :value="null" :name="null">
    <input
      :value="val"
      type="radio"
      class="sr-only"
      :checked="checked"
      v-on="inputListeners"
      v-bind="$attrs"
      :id="_uid"
    />

    <!-- icon -->
    <span class="form-radio-input" />

    <!-- label -->
    <span class="form-radio-text" v-if="$slots.default">
      <slot />
    </span>
    <span v-else v-text="text" class="form-radio-text" />
  </label>
</template>

<script>
export default {
  name: 'VRadio',

  props: {
    val: String,
    text: String,
    size: {
      type: String,
      default: 'medium',
      validator: (v) => ['small', 'medium'].includes(v),
    },
  },

  computed: {
    clazz() {
      return [
        {
          '--checked': this.checked,
          '--disabled': this.$attrs.disabled,
        },
        `--${this.size}`,
      ]
    },

    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: () => this.$emit('input', this.val),
      })
    },

    checked() {
      return this.$attrs.value === this.val
    },
  },
}
</script>

<style lang="scss">
.form-radio {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  margin: 0;

  &.--disabled {
    cursor: no-drop;
  }

  &-input {
    position: relative;
    width: 1em;
    height: 1em;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $color-primary;
    background-color: white;
    border-radius: 999px;
    border: 1px solid $color-dark-20;

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      font-size: 0;
      background-color: white;
      border-radius: inherit;
    }
  }

  &-text {
    white-space: nowrap;
    margin-left: $spacer-2;
    font-weight: $font-weight-medium !important;
  }

  /* Size small */
  &.--small &-input {
    font-size: 14px;

    &:after {
      width: 6px;
      height: 6px;
    }
  }

  &.--small &-text {
    font-size: 12px;
  }

  &.--disabled &-text {
    color: $color-dark-50;
  }

  &.--checked &-input {
    border-color: $color-primary;

    &:after {
      background-color: $color-primary;
    }
  }

  &.--disabled &-input {
    border-color: $color-dark-20 !important;

    &:after {
      opacity: 0.5;
    }
  }
}
</style>
