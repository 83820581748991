import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'
import { PagingQueryResultDto } from '@/api/Services/License/dto/paging-query-result.dto'
import { HTTPClient } from '@/api/HTTPClient'
import { LicenseHistoryEntityDto } from '@/api/Services/License/dto/response/license-history-entity-dto'

export class LicenseHistoryService {
  static getHistory(params: {
    pageSize: number
    pageNumber: number
    licenseKey: string
  }): Promise<AxiosResponse<ResponseDto<PagingQueryResultDto<LicenseHistoryEntityDto[]>>>> {
    return HTTPClient.get('/oem/history', params)
  }
}
