<template>
  <component
    :is="tag"
    class="btn"
    :class="clazz"
    v-bind="$attrs"
    v-on="$listeners"
    :type="$attrs.type || 'button'"
    :to="$attrs.disabled ? '' : $attrs.to"
    :disabled="$attrs.disabled || loading"
  >
    <!-- Icon -->
    <FaIcon v-if="icon" :icon="icon" :class="{ 'mr-2': $slots.default || text }" />

    <!-- Content -->
    <slot>
      {{ text }}
    </slot>

    <!-- Loading -->
    <FaIcon v-if="loading" spin class="ml-2" icon="circle-notch" style="pointer-events: none" />
  </component>
</template>

<script>
export default {
  name: 'VButton',

  props: {
    icon: [String, Array],
    text: String,
    loading: Boolean,
    active: Boolean,
    size: {
      type: String,
      default: 'medium',
      validator: (v) => ['xx-small', 'x-small', 'small', 'medium'].includes(v),
    },
    direction: {
      type: String,
      default: 'center',
      validator: (v) => ['left', 'center', 'right'].includes(v),
    },
    tag: {
      type: String,
      default: 'button',
    },
    theme: {
      type: String,
      default: 'default',
      validator: (val) =>
        [
          'text',
          'text-primary',
          'text-danger',
          'text-success',
          'default',
          'primary',
          'danger',
          'success',
          'outline-primary',
          'outline-danger',
          'outline-success',
        ].includes(val),
    },
  },

  computed: {
    clazz() {
      return [
        `--${this.size}`,
        `--${this.theme}`,
        `--direction-${this.direction}`,
        { '--active': this.active },
        { '--disabled': this.$attrs.disabled },
        { '--loading': this.loading },
      ]
    },
  },
}
</script>

<style lang="scss">
.btn {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  cursor: pointer;
  border-radius: $radius;
  white-space: nowrap;
  font-weight: $font-weight-medium;
  user-select: none;
  @include transition(120ms);
  @include boxShadow(0 0 0 1px rgba(15, 33, 46, 0.05), 0 1px 3px rgba(15, 33, 46, 0.05));

  &.--direction-left {
    justify-content: flex-start;
  }

  &.--direction-center {
    justify-content: center;
  }

  &.--direction-right {
    justify-content: flex-end;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.--disabled {
    opacity: 0.7;
    cursor: no-drop !important;
  }

  &.--loading {
    opacity: 0.7;
    cursor: progress !important;
  }

  /* sizes */
  &.--xx-small {
    height: 24px;
    padding: 0 8px;
    font-size: $font-size-x-small;
  }

  &.--x-small {
    height: 28px;
    padding: 0 10px;
    font-size: $font-size-small;
  }

  &.--small {
    height: 32px;
    padding: 0 14px;
    font-size: $font-size-normal;
  }

  &.--medium {
    height: 34px;
    padding: 0 14px;
    font-size: $font-size-medium;
  }

  /* themes */
  &.--text {
    color: $color-dark-90;
    @include boxShadow(none);

    &:hover {
      background-color: $color-dark-3;
    }

    &.--active,
    &:active {
      background-color: $color-dark-10;
    }
  }

  &.--default {
    color: $color-dark-90;
    background-color: #fff;

    &:hover {
      background-color: $color-dark-3;
    }

    &.--active,
    &:active {
      background-color: $color-dark-10;
    }
  }

  &.--primary {
    color: #fff;
    background-color: $color-primary;
    border: 1px solid $color-primary;

    &:hover {
      background-color: lighten($color-primary, 5);
    }

    &.--active,
    &:active {
      background-color: darken($color-primary, 10);
    }
  }

  &.--success {
    color: #fff;
    background-color: $color-green;
    border: 1px solid $color-green;

    &:hover {
      background-color: lighten($color-green, 5);
    }

    &.--active,
    &:active {
      background-color: darken($color-green, 10);
    }
  }

  &.--danger {
    color: #fff;
    background-color: $color-red;
    border: 1px solid $color-red;

    &:hover {
      background-color: lighten($color-red, 5);
    }

    &.--active,
    &:active {
      background-color: darken($color-red, 10);
    }
  }

  &.--outline-success,
  &.--text-success {
    color: $color-green;
    border: 1px solid $color-green;
    background-color: transparent;

    &:hover {
      color: #fff;
      background-color: $color-green;
    }

    &.--active,
    &:active {
      color: #fff;
      background-color: darken($color-green, 10);
    }
  }

  &.--outline-primary,
  &.--text-primary {
    color: $color-primary;
    background-color: transparent;
    border: 1px solid $color-primary;

    &:hover {
      color: #fff;
      background-color: $color-primary;
    }

    &.--active,
    &:active {
      color: #fff;
      background-color: darken($color-primary, 10);
    }
  }

  &.--outline-danger,
  &.--text-danger {
    color: $color-red;
    background-color: transparent;
    border: 1px solid $color-red;

    &:hover {
      color: #fff;
      background-color: $color-red;
    }

    &.--active,
    &:active {
      color: #fff;
      background-color: darken($color-red, 10);
    }
  }

  &.--text-primary,
  &.--text-success,
  &.--text-danger {
    border: 0;
    @include boxShadow(none);
  }
}
</style>
