<template>
  <el-dialog
    :title="`${isUpdate ? 'Update' : 'Create'} Version`"
    :visible.sync="showPanel"
    center
    width="700px"
    top="1vh"
    align-center="true"
    :before-close="closeNewTokenPanel"
  >
    <el-form :model="form" ref="formRef" class="version-form">
      <!-- Version -->
      <span class="input-label">Token</span>
      <el-form-item prop="token" :rules="[{ required: true }]">
        <el-input v-model="form.token" class="input-element" />
      </el-form-item>

      <!-- Release Date -->
      <span class="input-label">Expiration Date</span>
      <el-form-item prop="expirationDate" :rules="[{ required: true }]">
        <el-date-picker
          v-model="form.expirationDate"
          type="date"
          placeholder="Pick a date"
          class="new-license-date-input"
        />
      </el-form-item>
    </el-form>

    <div class="panel-footer">
      <el-button @click="closeNewTokenPanel()">Cancel</el-button>
      <el-button type="primary" @click="onClickCreateNewToken" :loading="$wait.is($waiters.License.Tokens)"
        >Confirm</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { v5 as uuidv5 } from 'uuid'
import { v4 as uuidv4 } from 'uuid'
import _clone from 'lodash-es/clone'
import { ApiTokensService } from '@/api/Services/License/ApiTokensService'
import { CreateApiTokenDto } from '@/api/Services/License/dto/request/create-api-token.dto'

export default {
  name: 'NewTokenComponent',

  data() {
    return {
      form: {},
      showPanel: this.panelDisplayStatus,
    }
  },

  props: {
    panelDisplayStatus: Boolean,
    updateData: Object,
  },

  watch: {
    panelDisplayStatus: function () {
      this.updateShowPanelStatus()
    },
  },

  computed: {
    isUpdate() {
      return this.updateData !== null
    },
  },

  mounted() {
    if (this.updateData !== null) {
      this.form = _clone(this.updateData)
    } else {
      const MY_NAMESPACE = uuidv4()
      const result = Math.random().toString(36).substring(2)
      this.form = {
        token: uuidv5(result, MY_NAMESPACE),
        expirationDate: dayjs().toDate(),
      }
    }
  },

  methods: {
    updateShowPanelStatus() {
      this.showPanel = this.panelDisplayStatus
    },

    closeNewTokenPanel() {
      this.showPanel = false
      this.$emit('close', this.panelDisplayStatus)
    },

    createApiToken() {
      this.$request(
        async () => {
          if (this.isUpdate) {
            //await ApiTokensService.update(this.form.id, this.form)
          } else {
            const request = new CreateApiTokenDto()
            request.token = this.form.token
            request.expirationDate = dayjs(this.form.expirationDate).toDate()
            await ApiTokensService.create(request)
            const MY_NAMESPACE = uuidv4()
            const result = Math.random().toString(36).substring(2)
            this.form = {
              token: uuidv5(result, MY_NAMESPACE),
              expirationDate: dayjs().toDate(),
            }
          }

          this.$emit('reload')
          this.closeNewTokenPanel()
        },
        this.$waiters.License.VersionsCreate,
        (e) => {
          console.error(e)
        },
      )
    },

    async onClickCreateNewToken() {
      await this.$refs.formRef.validate((valid) => {
        if (valid) {
          return this.createApiToken()
        }
        return false
      })
    },
  },
}
</script>

<style lang="scss">
.version-form {
  display: grid;
  width: 70%;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  gap: 15px 10px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;

  .input-label {
    font-weight: bold;

    &::after {
      content: ':';
    }
  }

  .input-element {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
}

.panel-footer {
  margin-left: 65%;
  margin-top: 15px;
  margin-bottom: -10px;
}
</style>
