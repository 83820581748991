<template>
  <el-container class="license-search-container">
    <el-input placeholder="Please input" v-model="searchTerm" class="input-with-select" @keyup.enter.native="search()">
      <el-select class="license-search-select" v-model="searchCriteria" slot="prepend" placeholder="Select">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <el-button slot="append" v-on:click="search()"><fa-icon icon="search"></fa-icon></el-button>
    </el-input>
  </el-container>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      searchTerm: '',
      options: [
        {
          value: 'license',
          label: 'License Key',
        },
        {
          value: 'name',
          label: 'Contact Name',
        },
        {
          value: 'email',
          label: 'Contact Email',
        },
        {
          value: 'company',
          label: 'Company',
        },
        {
          value: 'tag',
          label: 'Tag',
        },
      ],
      searchCriteria: '',
    }
  },
  mounted() {
    this.searchCriteria = this.options[0].value
  },
  methods: {
    search() {
      this.$router.push({ path: '/license/licenses', query: { term: this.searchTerm, crit: this.searchCriteria } })
    },
  },
}
</script>
<style>
.license-search-select {
  width: 140px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.license-search-container {
  margin-left: 10%;
  margin-right: 10%;
  min-width: 250px;
  margin-top: 20%;
}
</style>
