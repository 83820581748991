<template>
  <el-dialog append-to-body title="Delete License" :visible="show" width="380px" @close="$emit('close')">
    <h4>Are you sure to delete license with key</h4>
    <h4>{{ licenseKey }}?</h4>
    <br />
    <div>In order to do this, please enter <b>delete</b> below:</div>
    <br />
    <el-input v-model="confirm" />

    <div class="delete-license-buttons">
      <el-button type="danger" :disabled="!canDelete" @click="deleteLicense()">DELETE</el-button>
      <el-button type="secondary" @click="closeLicenseDeletePanel()">Cancel</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'licenseDeletePanel',

  data() {
    return {
      confirm: '',
    }
  },

  props: {
    show: Boolean,
    licenseKey: String,
  },

  methods: {
    closeLicenseDeletePanel() {
      this.$emit('close')
    },

    deleteLicense() {
      this.confirm = ''
      this.$emit('deleted')
      this.$emit('close')
    },
  },

  computed: {
    canDelete: function () {
      return this.confirm.toLowerCase() === 'delete'
    },
  },
}
</script>

<style lang="scss">
.delete-license-buttons {
  display: flex;
  padding-top: 25px;
  flex-flow: row;
  justify-content: space-between;
}
</style>
