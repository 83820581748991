<template>
  <div class="v-password-strength-meter">
    <slot />
    <ul v-show="show">
      <li v-for="rule in validationRules" :key="rule.text" :class="rule.valid ? 'c-green' : 'c-red'">
        <FaIcon :icon="rule.valid ? 'check' : 'times'" />
        {{ rule.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VPasswordStrengthMeter',

  props: {
    value: String,
  },

  data: () => ({
    show: false,
  }),

  computed: {
    validationRules() {
      return [
        {
          valid: this.value.length >= 8,
          text: this.$t('Includes at least 8 characters'),
        },
        {
          valid: /[a-z]/.test(this.value),
          text: this.$t('Includes lowercase letter'),
        },
        {
          valid: /[A-Z]/.test(this.value),
          text: this.$t('Includes uppercase letter'),
        },
        {
          valid: /[0-9]/.test(this.value),
          text: this.$t('Includes number'),
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.v-password-strength-meter {
  position: relative;

  ul {
    position: absolute;
    right: 0;
    left: 0;
    padding: 12px;
    margin-top: 5px;
    z-index: 9;
    border-radius: $radius-4;
    background-color: white;
    border: 1px solid $color-dark-10;
    @include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.1));

    li {
      margin-top: 10px;
      color: $color-dark-80;
      font-weight: $font-weight-medium;

      svg {
        margin-right: $spacer-2;
        width: 14px !important;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
