import { makeEnum } from '@/utils/Helpers'

export const Customer = makeEnum({
  Create: 'Customer_Create',
  Confirm: 'Customer_Confirm',
  AIRCustomers: 'AIRCustomers',
  OneCustomers: 'OneCustomers',
  AIRCustomerCaseSummary: 'AIRCustomerCaseSummary',
  OneCustomerDelete: 'OneCustomerDelete',
  MarkAsException: 'MarkAsException',
})

export const License = makeEnum({
  LicensesCreate: 'License_Create',
  LicensesConfirm: 'License_Confirm',
  Licenses: 'License_Get',
  Versions: 'Versions_Get',
  VersionsCreate: 'Versions_Create',
  UpdateTag: 'Versions_Tag_Update',
  Audits: 'Audits',
  License: 'License_Details_Get',
  Servers: 'License_Servers_Get',
  ServerDisable: 'License_Server_Disable',
  History: 'License_History_Get',
  Quotas: 'License_Quotas_Get',
  QuotaDateUpdate: 'License_Quotas_Date_Update',
  QuotaCapacityUpdate: 'License_Quotas_Capacity_Update',
  QuotaStatusUpdate: 'License_Quotas_Status_Update',
  Tags: 'License_Tags',
  TagEdit: 'License_Tag_Edit',
  TagDelete: 'License_Tag_Delete',
  LicenseTagsUpdate: 'License_Tags_Update',
  LicensesUpdate: 'License_Update',
  Users: 'User_Get',
  Tokens: 'Token_Get',
  Settings: 'Settings_Get',
})

export const OneMarketplace = makeEnum({
  SubscriptionPage: 'subscribe',
})
