import { LicenseTypeEnum } from '../../enums/license-type.enum'
import { LicenseStatusEnum } from '../../enums/license-status.enum'
import { IsArray, IsBoolean, IsDate, IsEnum, IsIn, IsNumber, IsOptional, IsString, Min } from 'class-validator'
import { LicenseDateFilterTypeEnum } from '../../enums/license-date-filter-type.enum'
import { LicenseUseStatusEnum } from '../../enums/license-use-status.enum'
import { LicenseLifetimeStatusEnum } from '../../enums/license-lifetime-status.enum'
import { LicenseActivenessStatusEnum } from '../../enums/license-activeness-status.enum'
import { Type } from 'class-transformer'
import { LicenseEnvironmentEnum } from '../../../../../../../src/license/enums/license-environment.enum'

export class LicenseFilteredRequestDto {
  @IsString()
  @IsOptional()
  key: string

  @IsString()
  @IsOptional()
  createdBy: string

  @IsBoolean()
  @IsOptional()
  isOffline: boolean

  @IsArray()
  @IsEnum(LicenseTypeEnum, { each: true })
  types: LicenseTypeEnum[] = []

  @IsArray()
  @IsEnum(LicenseStatusEnum, { each: true })
  statuses: LicenseStatusEnum[] = []

  @IsArray()
  @IsEnum(LicenseActivenessStatusEnum, { each: true })
  activenessStatuses: LicenseActivenessStatusEnum[] = []

  @IsEnum(LicenseEnvironmentEnum)
  @IsOptional()
  environment: string

  @IsArray()
  @IsEnum(LicenseLifetimeStatusEnum, { each: true })
  lifetimeStatuses: LicenseLifetimeStatusEnum[] = []

  @IsArray()
  @IsEnum(LicenseUseStatusEnum, { each: true })
  useStatuses: LicenseUseStatusEnum[] = []

  @IsOptional()
  @IsEnum(LicenseDateFilterTypeEnum)
  dateFilterType: LicenseDateFilterTypeEnum

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  maxDate: Date

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  minDate: Date

  @IsOptional()
  @IsString()
  tag: string

  @IsOptional()
  @IsString()
  companyName: string

  @IsOptional()
  @IsString()
  contactEmail: string

  @IsOptional()
  @IsNumber()
  @Min(0)
  pageNumber: number = 1

  @IsOptional()
  @IsNumber()
  @Min(0)
  pageSize: number = 10

  @IsOptional()
  @IsString()
  @IsIn([
    'createdAt',
    'activatedAt',
    'updatedAt',
    'key',
    'type',
    'createdBy',
    'status',
    'expiredAt',
    'companyName',
    'firstName',
    'lastName',
    'email',
    'countryCode',
    'totalClientCapacity',
    'serverCapacity',
    'caseCapacity',
    'serversInUse',
    'totalClientsInUse',
    'lastHeartbeat',
    'firstHeartbeat',
    'useStatus',
    'lifetimeStatus',
    'activenessStatus',
    'isValid',
  ])
  sortBy: string = 'createdAt'

  @IsOptional()
  @IsString()
  @IsIn(['ASC', 'DESC'])
  sortType: string = 'DESC'
}
