<template>
  <el-popover placement="top" width="600" v-model="visible">
    <div class="license-report-header">
      <span class="el-dialog__title license-filter-header-text">Get Report</span>
    </div>
    <div class="filter-report-grid">
      <el-button type="primary" @click="getLicenseReport(true)"
        >ACTIVE Licenses CSV of {{ LicenseEnvironmentEnumHelper.translateFromNumber(selectedEnvironment) }}</el-button
      >
      <el-button type="primary" @click="getLicenseReport(false)"
        >ALL Licenses CSV of {{ LicenseEnvironmentEnumHelper.translateFromNumber(selectedEnvironment) }}</el-button
      >
    </div>
    <el-button slot="reference">
      <fa-icon icon="clipboard" />
    </el-button>
  </el-popover>
</template>

<script>
import { LicenseEnvironmentEnumHelper } from '@/api/Services/License/enums/license-environment.enum'
import Papa from 'papaparse'
import { LicensesService } from '@/api/Services/License/LicensesService'
export default {
  name: 'LicenseReportPopover',
  computed: {
    LicenseEnvironmentEnumHelper() {
      return LicenseEnvironmentEnumHelper
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  props: {
    selectedEnvironment: Number,
  },
  methods: {
    closePanel() {
      this.visible = false
    },

    async getLicenseReport(isActive) {
      try {
        const response = await LicensesService.getReportCSV(
          JSON.stringify({ environment: this.selectedEnvironment }),
          isActive,
        )

        const csv = Papa.unparse(response.data.result)

        // Create a download link for the CSV
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${
          isActive ? 'active' : 'all'
        }_licenses_report-${LicenseEnvironmentEnumHelper.translateFromNumber(this.selectedEnvironment)}-${Date()
          .toString()
          .replaceAll(' ', '_')}.csv`
        link.click()
        this.closePanel()
      } catch (error) {
        console.error('Error generating CSV:', error)
        this.closePanel()
      }
    },
  },
}
</script>
<style scoped lang="scss">
.filter-report-grid {
  display: grid;
  width: 90%;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  gap: 15px 10px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}
.license-report-header {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdfe5;
  margin-bottom: 20px;
}
</style>
