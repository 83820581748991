<template>
  <el-dialog
    :title="`${isUpdate ? 'Update' : 'Create'} Version`"
    :visible.sync="showPanel"
    center
    width="700px"
    top="1vh"
    align-center="true"
    :before-close="closeNewVersionPanel"
  >
    <el-form :model="form" ref="formRef" class="version-form">
      <!-- Version -->
      <span class="input-label">Version</span>
      <el-form-item prop="version" :rules="[{ required: true }]">
        <el-input v-model="form.version" class="input-element" />
      </el-form-item>

      <!-- Release Date -->
      <span class="input-label">Released Date</span>
      <el-form-item prop="releasedAt" :rules="[{ required: true }]">
        <el-date-picker
          v-model="form.releasedAt"
          type="date"
          placeholder="Pick a date"
          class="new-license-date-input"
        />
      </el-form-item>

      <!-- is Active -->
      <span class="input-label">Active</span>
      <el-form-item prop="isActive" :rules="[{ required: true }]">
        <el-checkbox v-model="form.isActive">Active</el-checkbox>
      </el-form-item>
    </el-form>

    <div class="panel-footer">
      <el-button @click="closeNewVersionPanel()">Cancel</el-button>
      <el-button type="primary" @click="onClickCreateNewVersion" :loading="$wait.is($waiters.License.VersionsCreate)">
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { VersionsService } from '@/api/Services/License/VersionsService'
import _clone from 'lodash-es/clone'

export default {
  name: 'NewVersionComponent',

  data() {
    return {
      form: {},
      showPanel: this.panelDisplayStatus,
    }
  },

  props: {
    panelDisplayStatus: Boolean,
    updateData: Object,
  },

  watch: {
    panelDisplayStatus() {
      this.updateShowPanelStatus()
    },
  },

  computed: {
    isUpdate() {
      return this.updateData !== null
    },
  },

  mounted() {
    if (this.updateData !== null) {
      this.form = _clone(this.updateData)
    } else {
      this.form = {
        version: '',
        releasedAt: dayjs().toDate(),
        isActive: false,
        releaseNotes: '',
      }
    }
  },

  methods: {
    updateShowPanelStatus() {
      this.showPanel = this.panelDisplayStatus
    },

    closeNewVersionPanel() {
      this.showPanel = false
      this.$emit('displayStatus', this.panelDisplayStatus)
    },

    createVersion() {
      this.$request(
        async () => {
          if (this.isUpdate) {
            await VersionsService.Update(this.form.id, this.form)
          } else {
            await VersionsService.Create(this.form)
          }

          this.$emit('reload')
          this.closeNewVersionPanel()
        },
        this.$waiters.License.VersionsCreate,
        (e) => {
          console.error(e)
        },
      )
    },

    async onClickCreateNewVersion() {
      await this.$refs.formRef.validate((valid) => {
        if (valid) {
          return this.createVersion()
        }
        return false
      })
    },
  },
}
</script>

<style lang="scss">
.version-form {
  display: grid;
  width: 70%;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  gap: 15px 10px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;

  .input-label {
    font-weight: bold;

    &::after {
      content: ':';
    }
  }

  .input-element {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
}

.panel-footer {
  margin-left: 65%;
  margin-top: 15px;
  margin-bottom: -10px;
}
</style>
