<template>
  <el-form :model="customer" status-icon ref="customerForm" label-width="150px" class="new-license-form" size="small">
    <el-form-item label="Company Name" prop="company">
      <el-select
        v-model="customer.company"
        value-key="id"
        filterable
        remote
        placeholder="Please enter a Company Name"
        name="company"
        clearable
        :loading="companyLoading"
        :remote-method="getHubspotCompanies"
        @change="companySelected"
        @clear="companyCleared"
        class="new-license-input-element"
      >
        <el-option v-for="item in companyOptions" :key="item.id" :label="item.companyName" :value="item"> </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Email" prop="contact">
      <el-select
        v-model="customer.contact"
        value-key="id"
        filterable
        remote
        placeholder="Please enter a Contact Email"
        name="contact"
        clearable
        :remote-method="getHubspotContacts"
        :disabled="contactsDisabled"
        @change="contactSelected"
        @clear="contactCleared"
        class="new-license-input-element"
      >
        <el-option v-for="item in contactOptions" :key="item.id" :label="item.email" :value="item">
          <span style="float: left">{{ item.email }} </span>
          <span style="margin-left: 14px; float: right; color: #8492a6; font-size: 13px">
            {{ item.firstName + ' ' + item.lastName }}
          </span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Contact Name" v-if="this.customer.contact.firstName !== undefined">
      <span>{{ this.customer.contact.firstName + ' ' + this.customer.contact.lastName }}</span>
    </el-form-item>
  </el-form>
</template>

<script>
import { customersService } from '@/api/Services/License/CustomersService'

export default {
  name: 'CreateLicenseCompanyInfo',
  data: function () {
    return {
      customer: {
        company: {},
        contact: {},
      },
      companyLoading: false,
      contactLoading: false,
      contactsDisabled: true,
      companyOptions: [],
      contactOptions: [],
    }
  },

  props: {
    reset: Boolean,
  },

  watch: {
    reset: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.companyCleared()
      }
    },
  },

  methods: {
    getHubspotCompanies(query) {
      if (query !== '') {
        this.companyLoading = true
        this.$request(async () => {
          const { data } = await customersService.searchHubspotCompanies(query)
          this.companyLoading = false
          if (data.success) {
            this.companyOptions = data.result
          }
        })
      } else {
        this.companyOptions = []
        this.companyLoading = false
      }
    },

    getHubspotContacts(query) {
      if (query !== '') {
        this.contactLoading = true
        this.$request(async () => {
          const { data } = await customersService.searchHubspotContacts(this.customer.company.id, query)
          this.contactLoading = false
          if (data.success) {
            this.contactOptions = data.result
          }
        })
      } else {
        this.contactOptions = []
        this.contactLoading = false
      }
    },

    companySelected() {
      if (this.customer.company !== {}) {
        this.contactsDisabled = false
      } else {
        this.contactsDisabled = true
      }
      this.customer.contact = {}
      this.contactOptions = []
      this.selectionCancelled()
    },

    companyCleared() {
      this.customer.contact = {}
      this.customer.company = {}
      this.contactsDisabled = true
      this.companyOptions = []
      this.contactOptions = []
      this.selectionCancelled()
    },

    contactSelected() {
      if (this.customer.contact !== {}) {
        this.selectionFinalized()
      }
    },

    contactCleared() {
      this.contactOptions = []
      this.selectionCancelled()
      this.customer.contact = {}
    },

    selectionFinalized() {
      this.$emit('selectionCompleted', this.customer)
    },

    selectionCancelled() {
      this.$emit('selectionCancelled')
    },
  },
}
</script>
