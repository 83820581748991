import Vue from 'vue'
import Vuex from 'vuex'
import { AuthService } from '@/api/Services/AuthService'
import { UserService } from '@/api/Services/UserService'

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      isAuth: false,
      privileges: [],
      isAdmin: false,
    }
  },

  actions: {
    async checkUser({ state }) {
      try {
        await AuthService.Check()
        state.isAuth = true
        const privs = await UserService.getOwnPrivileges()
        state.isAdmin = privs.data.result.isAdmin
        state.privileges = privs.data.result.privileges
      } catch (e) {
        state.isAuth = false
      }
    },
  },
  getters: {
    checkPermissionsForVCan: (state) => (privilege) => {
      const perms = privilege.replace(/'/g, '').replace(/"/g, '').split(',')
      return !(state.isAdmin || state.privileges.some((p) => perms.includes(p)))
    },
    permissionCheck: (state) => (requiredPermission) => {
      return state.privileges.includes(requiredPermission) || state.isAdmin
    },
  },
  mutations: {
    logout(state) {
      state.isAdmin = false
      state.isAuth = false
      state.privileges = []
      AuthService.logout()
    },
  },
})
