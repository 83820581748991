import { IsArray, IsString } from 'class-validator'

export class UserPrivilegeManageDto {
  @IsString()
  email: string

  @IsArray()
  @IsString({ each: true })
  privileges: []
}
