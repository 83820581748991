import Axios from 'axios'

const client = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 120e3,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json, text/plain, */*',
  },
})

export class HTTPClient {
  static head(path) {
    return client.head(path)
  }

  static get(path, params = {}, headers = {}) {
    return client.get(path, {
      params,
      headers,
    })
  }

  static post(path, data, headers, cancelToken, onUploadProgress) {
    return client.post(path, data || {}, {
      headers,
      onUploadProgress,
    })
  }

  static put(path, data = {}, headers = {}) {
    return client.put(path, data, {
      headers,
    })
  }

  static patch(path, data = {}, headers = {}) {
    return client.patch(path, data, {
      headers,
    })
  }

  static delete(path, data = {}, headers = {}) {
    return client.delete(path, {
      data,
      headers,
    })
  }

  static setParam(key, value) {
    client.defaults.params = {
      ...(client.defaults.params || {}),
      [key]: value,
    }
  }

  static defaults() {
    return client.defaults
  }
}
