import { HTTPClient } from '@/api/HTTPClient'
import { AxiosResponse } from 'axios'
import { ResponseDto } from '@/api/Services/License/dto/response-dto'

export class SettingsService {
  static get(): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.get('/oem/license-settings')
  }

  static updateRenewalPeriod(renewalPeriod: number): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.put('/oem/license-settings/renewal-period', renewalPeriod)
  }

  static updateReminderDays(reminderDays: number[]): Promise<AxiosResponse<ResponseDto<any>>> {
    return HTTPClient.put('/oem/license-settings/reminder-days', reminderDays)
  }
}
