<template>
  <el-dialog append-to-body title="Update Tag" :visible="show" width="20%" @open="onDialogOpen" @close="$emit('close')">
    <el-form :model="form" ref="formRef">
      <!-- Value -->
      <span class="input-label">Tag</span>
      <el-form-item prop="tag" :rules="[{ required: true }]">
        <el-input v-model="form.tag" class="input-element" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeEditTagPanel">Cancel</el-button>
      <el-button type="primary" @click="onClickUpdateTag" :loading="$wait.is($waiters.License.UpdateTag)">
        Confirm
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { TagsService } from '@/api/Services/License/TagsService'

export default {
  name: 'updateVersionTagPanel',

  data() {
    return {
      form: {},
    }
  },

  props: {
    show: Boolean,
    tag: Object,
  },

  methods: {
    updateShowPanelStatus() {
      this.showPanel = this.panelDisplayStatus
    },

    closeEditTagPanel() {
      this.showPanel = false
      this.$emit('close')
    },

    updateTag() {
      const onSuccess = async () => {
        await TagsService.updateTag({ val: this.form.tag }, this.form.id)

        this.$emit('reload')
        this.closeEditTagPanel()
      }

      this.$request(onSuccess, this.$waiters.License.UpdateTag, (e) => {
        console.error(e)
      })
    },

    async onClickUpdateTag() {
      await this.$refs.formRef.validate((valid) => {
        if (valid) {
          return this.updateTag()
        }
        return false
      })
    },

    onDialogOpen() {
      this.form = {
        id: this.tag.id,
        tag: this.tag.val,
      }
    },
  },
}
</script>
