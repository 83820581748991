<template>
  <div class="license-devices-view">
    <el-table :data="servers" style="width: 100%" v-loading="$wait.is($waiters.License.Servers)">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-card>
            <div class="license-detail-headers">
              <span class="el-dialog__title license-filter-header-text">Detalis</span>
            </div>

            <div class="license-device-details-grid">
              <span class="license-general-input-label">Activated On</span>
              <span>{{ UtilityService.getFormattedDate(props.row.activatedAt, DateFormats.MINUTES) }}</span>
            </div>
            <div class="license-device-details-grid">
              <span class="license-general-input-label">Last Contact</span>
              <span>{{ UtilityService.getFormattedDate(props.row.lastHeartbeat, DateFormats.MINUTES) }}</span>
            </div>

            <div class="license-detail-headers">
              <span class="el-dialog__title license-filter-header-text">Serials</span>
            </div>
            <el-table :data="props.row.serials">
              <el-table-column label="Type" width="200"
                ><template slot-scope="props">{{ getSerialTypeName(props.row.type) }}</template>
              </el-table-column>
              <el-table-column label="Value" prop="value"> </el-table-column>
            </el-table>
          </el-card>
        </template>
      </el-table-column>
      <el-table-column label="Device ID" prop="deviceId" width="600"> </el-table-column>
      <el-table-column label="Asset Count" prop="clientsInUse" width="110"> </el-table-column>
      <el-table-column label="Status" width="60">
        <template slot-scope="props">
          <FaIcon
            :class="props.row.status === 1 ? 'c-green' : 'c-red'"
            :icon="props.row.status === 1 ? 'check' : 'times'"
          />
        </template>
      </el-table-column>
      <el-table-column label="Last Contact">
        <template slot-scope="props">{{
          UtilityService.getFormattedDate(props.row.lastHeartbeat, DateFormats.MINUTES)
        }}</template>
      </el-table-column>
      <el-table-column v-if="hasPermission('license-manage')">
        <template slot-scope="scope">
          <div>
            <el-popconfirm
              title="Disabled servers cannot be used until enabled. Are you sure?"
              @confirm="onClickEnable(scope.row)"
              v-if="scope.row.status === 1"
            >
              <template #reference>
                <el-button
                  type="text"
                  size="small"
                  :loading="$wait.is($waiters.License.ServerDisable)"
                  v-text="$t('Disable')"
                >
                </el-button>
              </template>
            </el-popconfirm>

            <el-popconfirm title="Are you sure?" @confirm="onClickEnable(scope.row)" v-if="scope.row.status !== 1">
              <template #reference>
                <el-button
                  type="text"
                  size="small"
                  :loading="$wait.is($waiters.License.ServerDisable)"
                  v-text="$t('Enable')"
                >
                </el-button>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handlePaginationPageChange"
      @size-change="handlePaginationSizeChange"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      layout="sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :total="totalEntityCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import { ServerService } from '@/api/Services/License/ServerService'
import * as $waiters from '@/constants/Waiters'
import { DateFormats, UtilityService } from '../../../../../api/Services/License/UtilityService'
import { SerialTypeEnum } from '../../../../../api/Services/License/enums/serial-type.enum'

export default {
  name: 'LicenseDevicesInfo',

  computed: {
    DateFormats() {
      return DateFormats
    },

    UtilityService() {
      return UtilityService
    },

    $waiters() {
      return $waiters
    },
  },

  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      servers: [],
      totalEntityCount: 0,
    }
  },

  methods: {
    getServerDetails() {
      this.getServerDetailsParameterized(this.pageSize, this.pageNum)
    },

    getServerDetailsParameterized(pageSize, pageNum) {
      this.$request(async () => {
        const req = {}
        req.pageSize = pageSize
        req.pageNumber = pageNum
        req.licenseKey = this.licenseDetails.key
        const { data } = await ServerService.getServers(req)
        if (data.success) {
          this.servers = data.result.entities
          this.pageNum = data.result.currentPage
          this.pageSize = data.result.pageSize
          this.totalEntityCount = data.result.totalEntityCount
        }
      }, this.$waiters.License.Servers)
    },

    getSerialTypeName(type) {
      return SerialTypeEnum[type]
    },

    onClickEnable(server) {
      const onSuccess = async () => {
        const payload = {
          status: server.status === 1 ? 0 : 1,
        }

        await ServerService.updateStatus(payload, server.id)
        this.$emit('reload')
      }

      this.$request(onSuccess, this.$waiters.License.QuotaStatusUpdate)
    },

    handlePaginationPageChange(val) {
      this.getServerDetailsParameterized(this.pageSize, val)
    },

    handlePaginationSizeChange(val) {
      this.getServerDetailsParameterized(val, this.currentPage)
    },
  },

  props: {
    licenseDetails: Object,
  },

  watch: {
    licenseDetails() {
      this.getServerDetails()
    },
  },
}
</script>

<style lang="scss">
.license-device-details {
  display: flex;
}

.license-device-details-grid {
  display: grid;
  width: 90%;
  grid-template-columns: 20% 80%;
  grid-template-rows: auto;
  gap: 0 50px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 16px;
}

.license-detail-headers {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdfe5;
  margin-bottom: 25px;
}
</style>
